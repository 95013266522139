import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";

import classes from "./classes.module.scss";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import NavTabOutlet from "components/elements/NavTabOutlet";

const MOSubscriptionPages = container.resolve(ModuleConfig).get().modules.pages.MOSubscriptions.props.pages;

function MOSubscriptions() {
	return (
		<PageTemplate tabTitle={I18n.asset.pages.mo_subscriptions.page_title} headerTitle={I18n.asset.pages.mo_subscriptions.title}>
			<div className={classes["root"]}>
				<NavTabOutlet
					items={[
						{ label: I18n.asset.pages.mo_subscriptions.sub_pages.draft.page_title, path: MOSubscriptionPages.Draft.props.path },
						{ label: I18n.asset.pages.mo_subscriptions.sub_pages.to_process.page_title, path: MOSubscriptionPages.ToProcess.props.path },
						{ label: I18n.asset.pages.mo_subscriptions.sub_pages.waiting_signature.page_title, path: MOSubscriptionPages.WaitingSignature.props.path },
						{ label: I18n.asset.pages.mo_subscriptions.sub_pages.waiting_validation.page_title, path: MOSubscriptionPages.WaitingValidation.props.path },
						{ label: I18n.asset.pages.mo_subscriptions.sub_pages.validated.page_title, path: MOSubscriptionPages.Validated.props.path },
					]}
				/>
			</div>
		</PageTemplate>
	);
}

export default MOSubscriptions;
