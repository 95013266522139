"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAutreCgpCorporationGroups = void 0;
exports.EAutreCgpCorporationGroups = {
    appreciationClientCorporation: "appreciationClientCorporation",
    appreciationOperationCorporation: "appreciationOperationCorporation",
    modeEntreeRelationCorporation: "modeEntreeRelationCorporation",
    commentConnuConsultimCorporation: "commentConnuConsultimCorporation",
    dateEntreeRelationCorporation: "dateEntreeRelationCorporation",
    coherenceSituationPatrimonialeEtInvestissementCorporation: "coherenceSituationPatrimonialeEtInvestissementCorporation",
};
