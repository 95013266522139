import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import Button from "components/elements/Button";
import LineInformation, { ELineInFoFormatType } from "components/elements/LineInformation";
import NavTabComponent from "components/elements/NavTabComponent";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";
import DateUtils from "utils/DateUtils";

import classes from "./classes.module.scss";
import TooltipElement from "components/elements/TooltipElement";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import ProductSubscribedToFileResponseResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileResponseResource";

const productSubscribedService = container.resolve(ProductSubscribedService);
const pages = container.resolve(ModuleConfig).get().modules.pages;

export default function SubscriptionInformation() {
	const navigate = useNavigate();
	const [productSubscribed, setProductSubscribed] = useState<ProductSubscribedResponseResource | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const [productSubScribedToFiles, setProductSubScribedToFiles] = useState<ProductSubscribedToFileResponseResource[]>([]);
	const { productSubscribedId } = useParams();

	useEffect(() => {
		setLoading(true);
		if (!productSubscribedId) return;
		productSubscribedService.getByUid(productSubscribedId).then((productSubscribed) => {
			setProductSubscribed(productSubscribed);
			setLoading(false);
		});
	}, [productSubscribedId]);

	useEffect(() => {
		if (!productSubscribed || loading) return;

		const files = productSubscribed.productSubscribedToFiles
			?.map((productSubscribedToFile) => {
				if (productSubscribedToFile.type !== ESubFilesTypes.SCPI) return null;
				productSubscribedToFile.file.filePath = productSubscribedService.buildFileUrlByFileId(productSubscribedToFile.file.id);
				return productSubscribedToFile;
			})
			.filter((file) => file !== null) as ProductSubscribedToFileResponseResource[];

		setProductSubScribedToFiles(files);
	}, [productSubscribed, loading]);

	const dueDate = useMemo(
		() => DateUtils.getDueDate(productSubscribed?.validationDate, productSubscribed?.productSheet?.dueDays, productSubscribed?.productSheet?.dueMonths),
		[productSubscribed],
	);

	const payoutDate = useMemo(() => DateUtils.getPayoutDate(dueDate, productSubscribed?.productSheet?.distributionFrequency), [dueDate, productSubscribed]);

	if (!productSubscribedId) return;

	let title = I18n.trslt(I18n.asset.pages.subscription_information.page_title, {
		productName: productSubscribed?.productSheet?.name ?? "-",
		civility: productSubscribed?.subscriber?.civility ?? "-",
		subscriberName: productSubscribed?.subscriber?.lastName ?? "-",
	});

	if (productSubscribed?.corporationSubscriber) {
		title = I18n.trslt(I18n.asset.pages.subscription_information.page_title_corporation, {
			productName: productSubscribed?.productSheet?.name ?? "-",
			corporationName: productSubscribed?.corporationSubscriber?.raisonSociale ?? "-",
		});
	}

	return (
		<PageTemplate
			tabTitle={title}
			rightElement={
				<Button onClick={() => navigate(pages.SubscriptionSummary.props.pages.Subscribers.props.path.replace(":productSubscribedId", productSubscribedId))}>
					{I18n.asset.common.see_detail}
				</Button>
			}
			backArrow={{ enabled: true }}>
			<div className={classes["root"]}>
				<Typography typo={ITypo.DISPLAY_MEDIUM} color={ITypoColor.WILD_SAND_950}>
					{title}
				</Typography>
				<section className={classes["info"]}>
					<Typography className={classes["title"]} typo={ITypo.H2} color={ITypoColor.WILD_SAND_950}>
						{I18n.asset.pages.subscription_information.title}
					</Typography>
					<LineInformation
						label={I18n.asset.pages.subscription_information.validation_date}
						value={productSubscribed?.validationDate}
						formatType={ELineInFoFormatType.DATE}
					/>
					<LineInformation label={I18n.asset.pages.subscription_information.due_date} value={dueDate} formatType={ELineInFoFormatType.DATE} />
					<LineInformation label={I18n.asset.pages.subscription_information.payout_date} value={payoutDate} />
					<LineInformation label={I18n.asset.pages.subscription_information.share_number} value={productSubscribed?.quantity} formatType={ELineInFoFormatType.PART} />
					<LineInformation
						label={I18n.asset.pages.subscription_information.amount_subscribed}
						value={productSubscribed?.productSheet?.price && productSubscribed.quantity * productSubscribed.productSheet.price}
						formatType={ELineInFoFormatType.EURO}
					/>
					<LineInformation
						label={I18n.asset.pages.subscription_information.estimation_amount}
						value={productSubscribed?.productSheet?.withdrawalPrice && productSubscribed.quantity * productSubscribed.productSheet.withdrawalPrice}
						formatType={ELineInFoFormatType.EURO}
						icon={
							<TooltipElement title={I18n.asset.pages.subscription_information.estimation_amount_info}>
								<InformationCircleIcon height={24} width={24} style={{ cursor: "help" }} />
							</TooltipElement>
						}
					/>
				</section>

				{/* TODO get the files */}
				<NavTabComponent
					items={productSubScribedToFiles.map((productSubScribedToFile) => {
						if (!productSubScribedToFile.label) return null;
						return {
							label: productSubScribedToFile.label,
							children: (
								<object
									aria-label={productSubScribedToFile.label}
									data={productSubScribedToFile.file.filePath!}
									width={"100%"}
									height={"900px"}
									type="application/pdf"
								/>
							),
						};
					})}
				/>
			</div>
		</PageTemplate>
	);
}
