import { ESubStep } from "common/enums/Subscription/ESubStep";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";

import { getSubscriberIdentity } from "components/pages/Subscriptions/tabs/elements/SubscriptionsTable";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";

const productSubscribedService = container.resolve(ProductSubscribedService);
const configService = container.resolve(ApplicationConfig);

type IProps = {
	subSteps: ESubStep[];
	dateLabel: string;
};

export default function SubscriptionsTableDraft(props: IProps) {
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });

	const search = useRef<string | null>(null);
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });

	useEffect(() => {
		productSubscribedService.countByStepsAdmin(props.subSteps).then(setCount);
	}, [props.subSteps]);

	const fetchData = useCallback(
		async () =>
			productSubscribedService.getAllByStepAdmin(props.subSteps, pagination.current, search.current).then((data) => {
				if (data.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(data, props.subSteps)]);
				pagination.current.skip += pagination.current.take;
				return data;
			}),
		[props.subSteps],
	);

	const onNext = useCallback(
		(release: () => void) => {
			fetchData().then((data) => {
				if (!data.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchData],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	const columsHead: TableColumn[] = useMemo(
		() => [
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.full_name,
				key: "subscriber",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.type,
				key: "type",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.cabinet_name,
				key: "cabinet_name",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.cgp_email,
				key: "cgp_email",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.cgp_phone,
				key: "cgp_phone",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.scpi_name,
				key: "scpi_name",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.amount,
				formatType: ECellType.EURO,
				key: "amount",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.number_of_shares,
				key: "number_of_shares",
			},
			{
				headContent: props.dateLabel,
				key: "date",
			},
		],
		[props.dateLabel],
	);

	return (
		<Table
			count={count.count}
			columnsHead={columsHead}
			rows={rows}
			placeholderSearchbar={I18n.asset.pages.mo_subscriptions.sub_pages.table.find_subscription}
			onNext={onNext}
			onSearch={onSearch}
		/>
	);
}

function buildRows(products: ProductSubscribedResponseResource[], subSteps: ESubStep[]): IRowProps[] {
	return products.map((product) => {
		return {
			rowKey: product.id,
			subscriber: getSubscriberIdentity(product),
			type: I18n.asset.enums.ESubscriptionType[product.subscription?.subscriptionType as ESubscriptionType],
			cabinet_name: product.subscription?.creator?.offices![0]?.raisonSociale ?? "",
			cgp_email: product.subscription?.creator?.email ?? "",
			cgp_phone: product.subscription?.creator?.phoneNumber ?? "",
			scpi_name: product.productSheet!.name,
			number_of_shares: product.quantity,
			amount: product.quantity * product.productSheet!.price,

			date: getDateValue(product, subSteps),
		};
	});
}

function getDateValue(product: ProductSubscribedResponseResource, subSteps: ESubStep[]): string {
	return (subSteps[0] === ESubStep.validated ? new Date(product.validationDate ?? "") : new Date(product.updatedAt ?? "")).toLocaleDateString();
}
