import { ECountry } from "common/enums/Country/ECountry";

export const ECountryImplementation: Record<ECountry, string> = {
	afghanistan: "Afghanistan",
	afrique_du_sud: "Afrique du Sud",
	albanie: "Albanie",
	algerie: "Algérie",
	allemagne: "Allemagne",
	andorre: "Andorre",
	angola: "Angola",
	antigua_et_barbuda: "Antigua-et-Barbuda",
	arabie_saoudite: "Arabie saoudite",
	argentine: "Argentine",
	armenie: "Arménie",
	australie: "Australie",
	autriche: "Autriche",
	azerbaidjan: "Azerbaïdjan",
	bahamas: "Bahamas",
	bahrein: "Bahreïn",
	bangladesh: "Bangladesh",
	barbade: "Barbade",
	bielorussie: "Biélorussie",
	belgique: "Belgique",
	belize: "Belize",
	benin: "Bénin",
	bhoutan: "Bhoutan",
	bolivie: "Bolivie",
	bosnie_herzegovine: "Bosnie-Herzégovine",
	botswana: "Botswana",
	bresil: "Brésil",
	brunei: "Brunei",
	bulgarie: "Bulgarie",
	burkina_faso: "Burkina Faso",
	burundi: "Burundi",
	cambodge: "Cambodge",
	cameroun: "Cameroun",
	canada: "Canada",
	cap_vert: "Cap-Vert",
	republique_centrafricaine: "République centrafricaine",
	chili: "Chili",
	chine: "Chine",
	chypre: "Chypre",
	colombie: "Colombie",
	comores: "Comores",
	republique_du_congo: "République du Congo",
	republique_democratique_du_congo: "République démocratique du Congo",
	coree_du_sud: "Corée du Sud",
	coree_du_nord: "Corée du Nord",
	costa_rica: "Costa Rica",
	cote_d_ivoire: "Côte d'Ivoire",
	croatie: "Croatie",
	cuba: "Cuba",
	danemark: "Danemark",
	djibouti: "Djibouti",
	republique_dominicaine: "République dominicaine",
	dominique: "Dominique",
	egypte: "Égypte",
	emirats_arabes_unis: "Émirats arabes unis",
	equateur: "Équateur",
	erythree: "Érythrée",
	espagne: "Espagne",
	estonie: "Estonie",
	etats_unis: "États-Unis",
	ethiopie: "Éthiopie",
	fidji: "Fidji",
	finlande: "Finlande",
	france: "France",
	gabon: "Gabon",
	gambie: "Gambie",
	georgie: "Géorgie",
	ghana: "Ghana",
	grece: "Grèce",
	grenade: "Grenade",
	guatemala: "Guatemala",
	guinee: "Guinée",
	guinee_bissau: "Guinée-Bissau",
	guinee_equatoriale: "Guinée équatoriale",
	guyana: "Guyana",
	haiti: "Haïti",
	honduras: "Honduras",
	hongrie: "Hongrie",
	inde: "Inde",
	indonesie: "Indonésie",
	iran: "Iran",
	irak: "Irak",
	irlande: "Irlande",
	islande: "Islande",
	israel: "Israël",
	italie: "Italie",
	jamaique: "Jamaïque",
	japon: "Japon",
	jordanie: "Jordanie",
	kazakhstan: "Kazakhstan",
	kenya: "Kenya",
	kirghizistan: "Kirghizistan",
	kiribati: "Kiribati",
	koweit: "Koweït",
	laos: "Laos",
	lesotho: "Lesotho",
	lettonie: "Lettonie",
	liban: "Liban",
	liberia: "Liberia",
	libye: "Libye",
	liechtenstein: "Liechtenstein",
	lituanie: "Lituanie",
	luxembourg: "Luxembourg",
	macedoine_du_nord: "Macédoine du Nord",
	madagascar: "Madagascar",
	malaisie: "Malaisie",
	malawi: "Malawi",
	maldives: "Maldives",
	mali: "Mali",
	malte: "Malte",
	maroc: "Maroc",
	iles_marshall: "Îles Marshall",
	maurice: "Maurice",
	mauritanie: "Mauritanie",
	mexique: "Mexique",
	etats_federes_de_micronesie: "États fédérés de Micronésie",
	moldavie: "Moldavie",
	monaco: "Monaco",
	mongolie: "Mongolie",
	montenegro: "Monténégro",
	mozambique: "Mozambique",
	birmanie: "Birmanie",
	namibie: "Namibie",
	nauru: "Nauru",
	nepal: "Népal",
	nicaragua: "Nicaragua",
	niger: "Niger",
	nigeria: "Nigeria",
	norvege: "Norvège",
	nouvelle_zelande: "Nouvelle-Zélande",
	nouvelle_caledonie: "Nouvelle-Calédonie",
	oman: "Oman",
	ouganda: "Ouganda",
	ouzbekistan: "Ouzbékistan",
	pakistan: "Pakistan",
	palaos: "Palaos",
	panama: "Panama",
	papouasie_nouvelle_guinee: "Papouasie-Nouvelle-Guinée",
	paraguay: "Paraguay",
	pays_bas: "Pays-Bas",
	perou: "Pérou",
	philippines: "Philippines",
	pologne: "Pologne",
	portugal: "Portugal",
	qatar: "Qatar",
	roumanie: "Roumanie",
	royaume_uni: "Royaume-Uni",
	russie: "Russie",
	rwanda: "Rwanda",
	saint_christophe_et_nieves: "Saint-Christophe-et-Niévès",
	saint_marin: "Saint-Marin",
	saint_vincent_et_les_grenadines: "Saint-Vincent-et-les-Grenadines",
	sainte_lucie: "Sainte-Lucie",
	iles_salomon: "Îles Salomon",
	salvador: "Salvador",
	samoa: "Samoa",
	sao_tome_et_principe: "Sao Tomé-et-Principe",
	senegal: "Sénégal",
	serbie: "Serbie",
	seychelles: "Seychelles",
	sierra_leone: "Sierra Leone",
	singapour: "Singapour",
	slovaquie: "Slovaquie",
	slovenie: "Slovénie",
	somalie: "Somalie",
	soudan: "Soudan",
	soudan_du_sud: "Soudan du Sud",
	sri_lanka: "Sri Lanka",
	suede: "Suède",
	suisse: "Suisse",
	suriname: "Suriname",
	eswatini: "Eswatini",
	syrie: "Syrie",
	tadjikistan: "Tadjikistan",
	tanzanie: "Tanzanie",
	tchad: "Tchad",
	tchequie: "Tchéquie",
	thailande: "Thaïlande",
	timor_oriental: "Timor oriental",
	togo: "Togo",
	tonga: "Tonga",
	trinite_et_tobago: "Trinité-et-Tobago",
	tunisie: "Tunisie",
	turkmenistan: "Turkménistan",
	turquie: "Turquie",
	tuvalu: "Tuvalu",
	ukraine: "Ukraine",
	uruguay: "Uruguay",
	vanuatu: "Vanuatu",
	venezuela: "Venezuela",
	viet_nam: "Viêt Nam",
	yemen: "Yémen",
	zambie: "Zambie",
	zimbabwe: "Zimbabwe",
};
