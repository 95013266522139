import Form from "components/materials/Form";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import React, { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import classes from "./classes.module.scss";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import Button from "components/elements/Button";
import { ValidationError } from "common/resources/Resource";
import { ISubscriptionOutletContext } from "..";
import GenericPatrimoineRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericPatrimoineRequestResource";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import MultiplePercentagesInput from "components/materials/Form/MultiplePercentagesInput";
import * as P from "ts-pattern";
import GlobalUtils from "utils/GlobalUtils";
import { EPatrimoineRevenusCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups";
import {
	ESubCategorisationMifCorporation,
	ESubOriginePatrimoineCorporation,
	ESubPartInvestissementPatrimoineGlobalCorporation,
	ESubStatutFactaCrsCorporation,
} from "common/enums/Scpi/Corporation/Patrimoine";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import MultipleNumbersInput from "components/materials/Form/MultipleNumbersInput";
import ActiveEnDehorsUeInput from "./ActiveEnDehorsUeInput";
import SituationFinancierePermetPlacerCorporationInput from "./SituationFinancierePermetPlacerCorporationInput";
import SubRevenusCompositionCorporationRequestResource from "common/resources/Scpi/Corporation/Patrimoine/SubRevenusCompositionCorporationRequestResource";
import SubPatrimoineCompositionCorporationRequestResource from "common/resources/Scpi/Corporation/Patrimoine/SubPatrimoineCompositionCorporationRequestResource";
import SubSituationFinanciereCorporationRequestResource from "common/resources/Scpi/Corporation/Patrimoine/SubSituationFinanciereCorporationRequestResource";
import FormUtils from "utils/FormUtils";
import { ECountry } from "common/enums/Country/ECountry";
import SocieteListeeCodeMonetaireInput from "./SocieteListeeCodeMonetaireInput";

export default function SubPatrimoineRevenusCorporation() {
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const { productAggregate, updateSubscription, getInputsFromProductAggregate, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath, subscriptionWithKnowledge } =
		useOutletContext<ISubscriptionOutletContext>();

	const [patrimoine, setPatrimoine] = useState<number | null>(subscriptionWithKnowledge.patrimoineRevenus?.patrimoine || null);
	const [revenus, setRevenus] = useState<number | null>(subscriptionWithKnowledge.patrimoineRevenus?.revenusAnnuelsBrutsCorporation || null);

	const inputsList: Record<EPatrimoineRevenusCorporationGroups, JSX.Element | null> = useMemo(
		() => getInputListSubPatrimoineRevenus(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);
	const getFormNumberOrZero = (value: unknown): number => {
		return value ? parseFloat(value as string) : 0;
	};

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			let productAggregateToUse = productAggregate;
			//If the fiscal regime of the user contains IFI then add the group needed to the agregate
			if (!subscriptionWithKnowledge.productsSubscribed?.[0]?.corporationSubscriber?.assujettiIFI) {
				productAggregateToUse = {
					...productAggregate,
					patrimoine_revenus: {
						...productAggregate.patrimoine_revenus,
						groups: productAggregate.patrimoine_revenus.groups.filter((group) => group !== EPatrimoineRevenusCorporationGroups.montantIFIAnneePrecedenteCorporation),
					},
				};
			}

			const resource = GenericPatrimoineRequestResourceCorporation.hydrate<GenericPatrimoineRequestResourceCorporation>(
				{
					category: "patrimoine_revenus",
					secteurActiviteCorporation: formData[EPatrimoineRevenusCorporationGroups.secteurActiviteCorporation] as string,
					nbSalariesCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.nbSalariesCorporation] as string),
					montantImpotAnneePrecedenteCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.montantImpotAnneePrecedenteCorporation] as string),
					montantIFIAnneePrecedenteCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.montantIFIAnneePrecedenteCorporation] as string),
					patrimoineCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.patrimoineCorporation] as string),
					compositionPatrimoineCorporation: SubPatrimoineCompositionCorporationRequestResource.hydrate<SubPatrimoineCompositionCorporationRequestResource>(
						{
							liquiditesTresorerie: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.liquiditesTresorerie`]),
							patrimoineFinancier: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.patrimoineFinancier`]),
							patrimoineImmobilier: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.patrimoineImmobilier`]),
							titresScpiOpci: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.titresScpiOpci`]),
						},
						{
							groups: productAggregateToUse.patrimoine_revenus.groups,
						},
					),
					situationFinanciereCorporation: SubSituationFinanciereCorporationRequestResource.hydrate<SubSituationFinanciereCorporationRequestResource>(
						{
							capitauxPropre: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.capitauxPropre`]),
							resultatNet: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.resultatNet`]),
							totalBilan: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.totalBilan`]),
							capitalSocial: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.capitalSocial`]),
							chiffreDAffaire: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.chiffreDAffaire`]),
						},
						{
							groups: productAggregateToUse.patrimoine_revenus.groups,
						},
					),
					originePatrimoineCorporation: FormUtils.getEnumValues<ESubOriginePatrimoineCorporation>(
						formData,
						EPatrimoineRevenusCorporationGroups.originePatrimoineCorporation,
						ESubOriginePatrimoineCorporation,
					),
					revenusAnnuelsBrutsCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.revenusAnnuelsBrutsCorporation] as string),
					compositionRevenusSocieteCorporation: SubRevenusCompositionCorporationRequestResource.hydrate<SubRevenusCompositionCorporationRequestResource>(
						{
							activite: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.activite`]),
							autre: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.autre`]),
							retraites: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.retraites`]),
							revenusFinanciers: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.revenusFinanciers`]),
							revenusImmobiliers: getFormNumberOrZero(formData[`${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.revenusImmobiliers`]),
						},
						{
							groups: productAggregateToUse.patrimoine_revenus.groups,
						},
					),
					situationFinancierePermetPlacerCorporation: FormUtils.getBooleanValue(formData, EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation),
					montantPermetPlacerCorporation: getFormNumberOrZero(
						formData[`${EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}.montantPermetPlacerCorporation`],
					),
					partInvestissementPatrimoineGlobalCorporation: formData[
						EPatrimoineRevenusCorporationGroups.partInvestissementPatrimoineGlobalCorporation
					] as ESubPartInvestissementPatrimoineGlobalCorporation,
					chargeEmpruntImmobilierAnnuelleCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.chargeEmpruntImmobilierAnnuelleCorporation] as string),
					chargeAutreEmpruntAnnuelleCorporation: parseFloat(formData[EPatrimoineRevenusCorporationGroups.chargeAutreEmpruntAnnuelleCorporation] as string),
					categorisationMifCorporation: formData[EPatrimoineRevenusCorporationGroups.categorisationMifCorporation] as ESubCategorisationMifCorporation,
					societeCoteeCorporation: FormUtils.getBooleanValue(formData, EPatrimoineRevenusCorporationGroups.societeCoteeCorporation),
					societeListeeCodeMonetaireCorporation: FormUtils.getBooleanValue(formData, EPatrimoineRevenusCorporationGroups.societeListeeCodeMonetaireCorporation),
					societeListeeCodeMonetaireCorporationPrecision: formData[
						`${EPatrimoineRevenusCorporationGroups.societeListeeCodeMonetaireCorporation}.societeListeeCodeMonetaireCorporationPrecision`
					] as string,
					societeUsPersonCorporation: FormUtils.getBooleanValue(formData, EPatrimoineRevenusCorporationGroups.societeUsPersonCorporation),
					statutFactaCrsCorporation: formData[EPatrimoineRevenusCorporationGroups.statutFactaCrsCorporation] as ESubStatutFactaCrsCorporation,
					detientPlusDroitsVoteCorporation: FormUtils.getBooleanValue(formData, EPatrimoineRevenusCorporationGroups.detientPlusDroitsVoteCorporation),
					activeEnDehorsUeCorporation: FormUtils.getBooleanValue(formData, EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation),
					paysActifEnDehorsUeCorporation:
						formData[`${EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}.paysActifEnDehorsUeCorporation`] === ""
							? null
							: (formData[`${EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}.paysActifEnDehorsUeCorporation`] as ECountry),
				},
				{
					groups: productAggregateToUse.patrimoine_revenus.groups,
				},
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregateToUse.patrimoine_revenus.groups });
				return updateSubscription(resource);
			} catch (err) {
				console.error(err);
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate, setCurrentSelectedPath, subscriptionWithKnowledge.productsSubscribed, updateSubscription],
	);

	const onRevenuChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setRevenus(value ? parseFloat(value) : null);
	}, []);

	const onPatrimoineChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setPatrimoine(value ? parseFloat(value) : null);
	}, []);

	const inputs = getInputsFromProductAggregate(inputsList);
	if (!inputs) return null;
	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>
				{inputs
					.filter((input) => input !== null)
					.map((input) => {
						const hydratedInput = React.cloneElement(input, {
							onChange: P.match(input.key)
								.with(EPatrimoineRevenusCorporationGroups.patrimoineCorporation, () => onPatrimoineChange)
								.with(EPatrimoineRevenusCorporationGroups.revenusAnnuelsBrutsCorporation, () => onRevenuChange)
								.otherwise(() => undefined),
							totalValue: P.match(input.key)
								.with(EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation, () => (patrimoine ? patrimoine : 0))
								.with(EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation, () => (revenus ? revenus : 0))
								.otherwise(() => undefined),
							hidden: P.match(input.key).otherwise(() => false),
						});
						return hydratedInput;
					})}
			</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubPatrimoineRevenus(
	subscription: SubscriptionResponseResource,
	readonly?: boolean,
	hideIfNotFilled: boolean = false,
): Record<EPatrimoineRevenusCorporationGroups, JSX.Element | null> {
	let showIFI = subscription.productsSubscribed?.[0]?.corporationSubscriber?.assujettiIFI;
	return {
		nbSalariesCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.nbSalariesCorporation) ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.nbSalariesCorporation}
					name={EPatrimoineRevenusCorporationGroups.nbSalariesCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.nbSalariesCorporation}
					defaultValue={subscription?.patrimoineRevenus?.nbSalariesCorporation?.toString()}
					readonly={readonly}
				/>
			),
		secteurActiviteCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.secteurActiviteCorporation) ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.secteurActiviteCorporation}
					name={EPatrimoineRevenusCorporationGroups.secteurActiviteCorporation}
					type={EInputType.TEXT}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.secteurActiviteCorporation}
					defaultValue={subscription?.patrimoineRevenus?.secteurActiviteCorporation?.toString()}
					readonly={readonly}
				/>
			),
		montantImpotAnneePrecedenteCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.montantImpotAnneePrecedenteCorporation) ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.montantImpotAnneePrecedenteCorporation}
					name={EPatrimoineRevenusCorporationGroups.montantImpotAnneePrecedenteCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.montantImpotAnneePrecedenteCorporation}
					defaultValue={subscription?.patrimoineRevenus?.montantImpotAnneePrecedenteCorporation?.toString()}
					readonly={readonly}
				/>
			),
		montantIFIAnneePrecedenteCorporation:
			(hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.montantIFIAnneePrecedente)) || !showIFI ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.montantIFIAnneePrecedenteCorporation}
					name={EPatrimoineRevenusCorporationGroups.montantIFIAnneePrecedenteCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.montantIFIAnneePrecedenteCorporation}
					defaultValue={subscription?.patrimoineRevenus?.montantIFIAnneePrecedenteCorporation?.toString()}
					readonly={readonly}
				/>
			),
		patrimoineCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.patrimoineCorporation ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.patrimoineCorporation}
					name={EPatrimoineRevenusCorporationGroups.patrimoineCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.patrimoineCorporation}
					defaultValue={subscription?.patrimoineRevenus?.patrimoineCorporation?.toString()}
					readonly={readonly}
				/>
			),
		compositionPatrimoineCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.compositionPatrimoineCorporation ? null : (
				<MultiplePercentagesInput
					key={EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}
					totalValue={subscription.patrimoineRevenus?.patrimoineCorporation ? subscription.patrimoineRevenus.patrimoineCorporation : 0}
					name={EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}
					readonly={readonly}
					inputs={[
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.patrimoineImmobilier`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoineCorporation.patrimoineImmobilier,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoineCorporation?.patrimoineImmobilier?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.patrimoineFinancier`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoineCorporation.patrimoineFinancier,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoineCorporation?.patrimoineFinancier?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.titresScpiOpci`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoineCorporation.titresScpiOpci,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoineCorporation?.titresScpiOpci?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation}.liquiditesTresorerie`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoineCorporation.liquiditesTresorerie,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoineCorporation?.liquiditesTresorerie?.toString(),
						},
					]}
				/>
			),
		situationFinanciereCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.situationFinanciereCorporation ? null : (
				<MultipleNumbersInput
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}
					key={EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}
					name={EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}
					readonly={readonly}
					inputs={[
						{
							name: `${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.capitauxPropre`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.situationFinanciereCorporation.capitauxPropre,
							defaultValue: subscription?.patrimoineRevenus?.situationFinanciereCorporation?.capitauxPropre?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.capitalSocial`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.situationFinanciereCorporation.capitalSocial,
							defaultValue: subscription?.patrimoineRevenus?.situationFinanciereCorporation?.capitalSocial?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.chiffreDAffaire`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.situationFinanciereCorporation.chiffreDAffaire,
							defaultValue: subscription?.patrimoineRevenus?.situationFinanciereCorporation?.chiffreDAffaire?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.totalBilan`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.situationFinanciereCorporation.totalBilan,
							defaultValue: subscription?.patrimoineRevenus?.situationFinanciereCorporation?.totalBilan?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.situationFinanciereCorporation}.resultatNet`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.situationFinanciereCorporation.resultatNet,
							defaultValue: subscription?.patrimoineRevenus?.situationFinanciereCorporation?.resultatNet?.toString(),
						},
					]}
				/>
			),
		originePatrimoineCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.originePatrimoineCorporation ? null : (
				<CheckboxesInputElement
					name={EPatrimoineRevenusCorporationGroups.originePatrimoineCorporation}
					key={EPatrimoineRevenusCorporationGroups.originePatrimoineCorporation}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.originePatrimoineCorporation}
					options={Object.keys(ESubOriginePatrimoineCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubOriginePatrimoineCorporation[key as keyof typeof ESubOriginePatrimoineCorporation],
							value: key as ESubOriginePatrimoineCorporation,
							defaultChecked: subscription.patrimoineRevenus?.originePatrimoineCorporation?.some((value) => value === key),
						};
					})}
					readonly={readonly}
				/>
			),
		revenusAnnuelsBrutsCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.revenusAnnuelsBrutsCorporation ? null : (
				//change is to a simple number input
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.revenusAnnuelsBrutsCorporation}
					name={EPatrimoineRevenusCorporationGroups.revenusAnnuelsBrutsCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.revenusAnnuelsBrutsCorporation}
					defaultValue={subscription?.patrimoineRevenus?.revenusAnnuelsBrutsCorporation?.toString()}
					readonly={readonly}
				/>
			),
		compositionRevenusSocieteCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.compositionRevenusSocieteCorporation ? null : (
				<MultiplePercentagesInput
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}
					key={EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}
					totalValue={subscription.patrimoineRevenus?.revenusAnnuelsBrutsCorporation ? subscription.patrimoineRevenus.revenusAnnuelsBrutsCorporation : 0}
					name={EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}
					readonly={readonly}
					inputs={[
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.revenusImmobiliers`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenusCorporation.revenusImmobiliers,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenusSocieteCorporation?.revenusImmobiliers?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.activite`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenusCorporation.activite,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenusSocieteCorporation?.activite?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.retraites`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenusCorporation.retraites,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenusSocieteCorporation?.retraites?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.revenusFinanciers`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenusCorporation.revenusFinanciers,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenusSocieteCorporation?.revenusFinanciers?.toString(),
						},
						{
							name: `${EPatrimoineRevenusCorporationGroups.compositionRevenusSocieteCorporation}.autre`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenusCorporation.autre,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenusSocieteCorporation?.autre?.toString(),
						},
					]}
				/>
			),
		situationFinancierePermetPlacerCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.situationFinancierePermetPlacerCorporation) ? null : (
				<SituationFinancierePermetPlacerCorporationInput
					key={EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}
					readonly={readonly}
					subscription={subscription}
				/>
			),
		partInvestissementPatrimoineGlobalCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.partInvestissementPatrimoineGlobalCorporation ? null : (
				<MuiSelectInputElement
					name={EPatrimoineRevenusCorporationGroups.partInvestissementPatrimoineGlobalCorporation}
					key={EPatrimoineRevenusCorporationGroups.partInvestissementPatrimoineGlobalCorporation}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.partInvestissementPatrimoineGlobalCorporation}
					options={Object.keys(ESubPartInvestissementPatrimoineGlobalCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubPartInvestissementPatrimoineGlobalCorporation[key as keyof typeof ESubPartInvestissementPatrimoineGlobalCorporation],
							id: ESubPartInvestissementPatrimoineGlobalCorporation[key as keyof typeof ESubPartInvestissementPatrimoineGlobalCorporation],
						};
					})}
					defaultValue={Object.keys(ESubPartInvestissementPatrimoineGlobalCorporation).reduce(
						(acc, key) => {
							return subscription?.patrimoineRevenus?.partInvestissementPatrimoineGlobalCorporation ===
								ESubPartInvestissementPatrimoineGlobalCorporation[key as keyof typeof ESubPartInvestissementPatrimoineGlobalCorporation]
								? {
										label: I18n.asset.enums.ESubPartInvestissementPatrimoineGlobalCorporation[
											key as keyof typeof ESubPartInvestissementPatrimoineGlobalCorporation
										],
										id: ESubPartInvestissementPatrimoineGlobalCorporation[key as keyof typeof ESubPartInvestissementPatrimoineGlobalCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		chargeEmpruntImmobilierAnnuelleCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.chargeEmpruntImmobilierAnnuelleCorporation) ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.chargeEmpruntImmobilierAnnuelleCorporation}
					name={EPatrimoineRevenusCorporationGroups.chargeEmpruntImmobilierAnnuelleCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.chargeEmpruntImmobilierAnnuelleCorporation}
					defaultValue={subscription?.patrimoineRevenus?.chargeEmpruntImmobilierAnnuelleCorporation?.toString()}
					readonly={readonly}
				/>
			),
		chargeAutreEmpruntAnnuelleCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.chargeAutreEmpruntAnnuelleCorporation) ? null : (
				<InputElement
					key={EPatrimoineRevenusCorporationGroups.chargeAutreEmpruntAnnuelleCorporation}
					name={EPatrimoineRevenusCorporationGroups.chargeAutreEmpruntAnnuelleCorporation}
					type={EInputType.NUMBER}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.chargeAutreEmpruntAnnuelleCorporation}
					defaultValue={subscription?.patrimoineRevenus?.chargeAutreEmpruntAnnuelleCorporation?.toString()}
					readonly={readonly}
				/>
			),
		categorisationMifCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.categorisationMifCorporation) ? null : (
				<RadioInputElement
					name={EPatrimoineRevenusCorporationGroups.categorisationMifCorporation}
					key={EPatrimoineRevenusCorporationGroups.categorisationMifCorporation}
					options={Object.keys(ESubCategorisationMifCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubCategorisationMifCorporation[key as keyof typeof ESubCategorisationMifCorporation],
							value: ESubCategorisationMifCorporation[key as keyof typeof ESubCategorisationMifCorporation],
						};
					})}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.categorisationMifCorporation}
					readonly={readonly}
					defaultValue={subscription.patrimoineRevenus?.categorisationMifCorporation?.toString() ?? ESubCategorisationMifCorporation.non_professionnel}
				/>
			),
		societeCoteeCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.societeCoteeCorporation) ? null : (
				<RadioInputElement
					name={EPatrimoineRevenusCorporationGroups.societeCoteeCorporation}
					key={EPatrimoineRevenusCorporationGroups.societeCoteeCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.societeCoteeCorporation}
					readonly={readonly}
					defaultValue={subscription.patrimoineRevenus?.societeCoteeCorporation?.toString() ?? "false"}
				/>
			),
		societeListeeCodeMonetaireCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.societeListeeCodeMonetaireCorporation) ? null : (
				<SocieteListeeCodeMonetaireInput subscription={subscription} key={EPatrimoineRevenusCorporationGroups.societeListeeCodeMonetaireCorporation} readonly={readonly} />
			),
		societeUsPersonCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.societeUsPersonCorporation) ? null : (
				<RadioInputElement
					name={EPatrimoineRevenusCorporationGroups.societeUsPersonCorporation}
					key={EPatrimoineRevenusCorporationGroups.societeUsPersonCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.societeUsPersonCorporation}
					readonly={readonly}
					defaultValue={subscription.patrimoineRevenus?.societeUsPersonCorporation?.toString() ?? "false"}
				/>
			),
		statutFactaCrsCorporation:
			hideIfNotFilled && !subscription.patrimoineRevenus?.statutFactaCrsCorporation ? null : (
				<MuiSelectInputElement
					name={EPatrimoineRevenusCorporationGroups.statutFactaCrsCorporation}
					key={EPatrimoineRevenusCorporationGroups.statutFactaCrsCorporation}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.statutFactaCrsCorporation}
					options={Object.keys(ESubStatutFactaCrsCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubStatutFactaCrsCorporation[key as keyof typeof ESubStatutFactaCrsCorporation],
							id: ESubStatutFactaCrsCorporation[key as keyof typeof ESubStatutFactaCrsCorporation],
						};
					})}
					defaultValue={Object.keys(ESubStatutFactaCrsCorporation).reduce(
						(acc, key) => {
							return subscription?.patrimoineRevenus?.statutFactaCrsCorporation === ESubStatutFactaCrsCorporation[key as keyof typeof ESubStatutFactaCrsCorporation]
								? {
										label: I18n.asset.enums.ESubStatutFactaCrsCorporation[key as keyof typeof ESubStatutFactaCrsCorporation],
										id: ESubStatutFactaCrsCorporation[key as keyof typeof ESubStatutFactaCrsCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		detientPlusDroitsVoteCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.detientPlusDroitsVoteCorporation) ? null : (
				<RadioInputElement
					name={EPatrimoineRevenusCorporationGroups.detientPlusDroitsVoteCorporation}
					key={EPatrimoineRevenusCorporationGroups.detientPlusDroitsVoteCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.detientPlusDroitsVoteCorporation}
					readonly={readonly}
					defaultValue={subscription.patrimoineRevenus?.detientPlusDroitsVoteCorporation?.toString() ?? "false"}
				/>
			),
		activeEnDehorsUeCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.activeEnDehorsUeCorporation) ? null : (
				<ActiveEnDehorsUeInput readonly={readonly} subscription={subscription} key={EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation} />
			),
	};
}
