import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfiniteScroll from "components/elements/InfiniteScroll";
import Typography, { ITypo } from "components/elements/Typography";
import NumberUtils from "utils/NumberUtils";

export type IRowProps = { rowKey: string } & Record<string, any>;

type IRow = {
	rowKey?: string;
	content: Record<string, CellContent>;
};

type IProps = {
	columnsHead: readonly TableColumn[];
	rows: IRowProps[];
	onNext?: ((release: () => void, reset?: () => void) => Promise<void> | void) | null;
};

export type TableColumn = {
	headContent: JSX.Element | string;
	key: string;
	formatType?: ECellType;
	widthPercentage?: number;
	canBeNegative?: boolean;
};

type CellContent = {
	cellKey: string;
	value: any;
	cellType?: ECellType | undefined;
	canBeNegative?: boolean;
};

export enum ECellType {
	DEFAULT = "default",
	EURO = "euro",
	EURO_WITHOUT_DECIMALS = "euro_without_decimals",
	UNITS = "units",
	PERCENTAGE = "percentage",
	DATE = "date",
}

export default function MuiTable(props: IProps) {
	const rows: IRow[] = props.rows.map((rowProps) => {
		const row: IRow = {
			rowKey: rowProps.rowKey,
			content: {},
		};
		props.columnsHead.forEach((column) => {
			const cellContent: CellContent = {
				cellKey: column.key + rowProps.rowKey,
				value: rowProps[column.key],
				cellType: column.formatType,
				canBeNegative: column.canBeNegative,
			};
			row.content[column.key] = cellContent;
		});
		return row;
	});

	return (
		<InfiniteScroll orientation="vertical" onNext={props.onNext} offset={0}>
			<TableContainer sx={{ border: "1px solid #DCDCDC", maxHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}>
				<Table aria-label="simple table">
					<TableHead sx={{ backgroundColor: "#EFEFEF", borderBottom: "1px solid #DCDCDC", position: "sticky", top: "0" }}>
						<TableRow>
							{props.columnsHead.map((column) => (
								<TableCell key={column.key} align={"left"} width={column.widthPercentage?.toString().concat("%")}>
									<Typography typo={ITypo.P_SMALL_BOLD}>{column.headContent}</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => {
							return (
								<TableRow key={row.rowKey} sx={{ backgroundColor: index % 2 ? "#F6F6F6" : "#FFF", border: "0", verticalAlign: "middle" }}>
									{Object.values(row.content).map((cell) => (
										<TableCell key={cell.cellKey} align="left" sx={{ border: "0" }}>
											{toFormat(cell.value, cell.cellType, cell.canBeNegative)}
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</InfiniteScroll>
	);

	function toFormat(value: any, formatType: TableColumn["formatType"], canBeNegative: boolean = true) {
		if (value === undefined || value === null) return "-";
		if (!canBeNegative && value < 0) return "-";
		switch (formatType) {
			case ECellType.UNITS:
				return (value ?? 0) + "/units";
			case ECellType.EURO:
				return NumberUtils.formatNumberAddingSpaces(value) + "\xa0€";
			case ECellType.EURO_WITHOUT_DECIMALS:
				return NumberUtils.formatNumberAddingSpaces(value, 0) + "\xa0€";
			case ECellType.PERCENTAGE:
				return value.toFixed(2) + "\xa0%";
			case ECellType.DATE:
				return value ? new Date(value).toLocaleDateString() : "-";
			default:
				return value;
		}
	}
}
