import { useCallback, useState } from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { EPaymentMethod } from "common/enums/ProductSubscribed/EPaymentMethod";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import Button from "components/elements/Button";
import MessageBox from "components/elements/MessageBox";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { container } from "tsyringe";
import ProductService from "services/ProductService";
import RadioInputElement from "components/materials/Form/RadioInputElement";

export type PrelevementInputs = {
	titularyName: string;
	bankDomiciliation: string;
	iban: string;
	bic: string;
};

type IProps = {
	className?: string;
	productSubscribed: ProductSubscribedResponseResource;
	isFirstPrelevement?: boolean;
	useSameRib?: boolean;
	onUseSameRibChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	readonly?: boolean;
	hidden?: boolean;
	onPrelevementChange?: (prelevementInputs: PrelevementInputs) => void;
};

const productService = container.resolve(ProductService);

export default function PaymentMethodInputElement(props: IProps) {
	const { className, readonly, hidden, productSubscribed, useSameRib, isFirstPrelevement, onUseSameRibChange, onPrelevementChange: onPrelevementChangeProps } = props;

	const [value, setValue] = useState<EPaymentMethod>(productSubscribed.paymentMethod);

	const [prelevementInputs, setPrelevementInputs] = useState<PrelevementInputs>({
		titularyName: productSubscribed.titularyName ?? "",
		bankDomiciliation: productSubscribed.bankDomiciliation ?? "",
		iban: productSubscribed.iban ?? "",
		bic: productSubscribed.bic ?? "",
	});

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setValue(e.target.value as EPaymentMethod);
		},
		[setValue],
	);

	const onPrelevementChange = useCallback(
		(type: keyof PrelevementInputs, value: string) => {
			setPrelevementInputs((prev) => ({ ...prev, [type]: value }));
			onPrelevementChangeProps?.({ ...prelevementInputs, [type]: value });
		},
		[onPrelevementChangeProps, prelevementInputs],
	);

	const onTitularyNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onPrelevementChange("titularyName", e.target.value);
		},
		[onPrelevementChange],
	);

	const onBankDomiciliationChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onPrelevementChange("bankDomiciliation", e.target.value);
		},
		[onPrelevementChange],
	);

	const onIbanChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onPrelevementChange("iban", e.target.value);
		},
		[onPrelevementChange],
	);

	const onBicChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onPrelevementChange("bic", e.target.value);
		},
		[onPrelevementChange],
	);

	const downloadRib = useCallback(async () => {
		const productId = productSubscribed.productSheet?.id;
		if (!productId) return console.warn("No product id found for this product.");
		const ribFilePath = await productService.getProductFiles(productId).then((productFiles) => {
			// reverse to take the last rib file
			const productFile = productFiles.reverse().find((product) => product.type === EProductFileType.rib);
			return productService.buildProductFileUrlByFileId(productFile?.fileId!);
		});
		if (!ribFilePath) return console.warn("No RIB file found for this product.");
		window.open(ribFilePath, "_blank");
	}, [productSubscribed.productSheet?.id]);

	const getContent = useCallback(
		(method: EPaymentMethod) => {
			if (!productSubscribed) return null;
			switch (method) {
				case EPaymentMethod.CHEQUE:
					return null;
				case EPaymentMethod.VIREMENT:
					return (
						<div className={classNames([classes["content"], value === method && classes["checked"]])}>
							<Button onClick={downloadRib}>{I18n.asset.component.form.payment_methods.download_rib}</Button>
						</div>
					);
				case EPaymentMethod.PRELEVEMENT:
					if (useSameRib && !isFirstPrelevement) return null;
					return (
						<div className={classNames([classes["content"], value === method && classes["checked"]])}>
							<div className={classes["inputs"]}>
								<InputElement
									name={`titularyName-${productSubscribed.id}`}
									label={I18n.asset.component.form.payment_methods.titulary_name}
									hidden={hidden}
									type={EInputType.TEXT}
									defaultValue={productSubscribed.titularyName ?? undefined}
									onChange={onTitularyNameChange}
									readonly={readonly}
								/>
								<InputElement
									name={`bankDomiciliation-${productSubscribed.id}`}
									label={I18n.asset.component.form.payment_methods.bank_domiciliation}
									hidden={hidden}
									type={EInputType.TEXT}
									defaultValue={productSubscribed.bankDomiciliation ?? undefined}
									onChange={onBankDomiciliationChange}
									readonly={readonly}
								/>
								<InputElement
									name={`iban-${productSubscribed.id}`}
									format="iban"
									label={I18n.asset.component.form.payment_methods.iban}
									hidden={hidden}
									type={EInputType.TEXT}
									autoComplete="on"
									defaultValue={productSubscribed.iban ?? undefined}
									onChange={onIbanChange}
									readonly={readonly}
								/>
								<InputElement
									name={`bic-${productSubscribed.id}`}
									label={I18n.asset.component.form.payment_methods.bic}
									hidden={hidden}
									type={EInputType.TEXT}
									defaultValue={productSubscribed.bic ?? undefined}
									onChange={onBicChange}
									readonly={readonly}
								/>
							</div>
							{!readonly && (
								<MessageBox className={classes["warning-prelevement"]} type="info" text={I18n.asset.pages.subscriptions.payment_methods.prelevement_info} />
							)}
						</div>
					);
				case EPaymentMethod.CREDIT:
					return (
						<div className={classNames([classes["content"], value === method && classes["checked"]])}>
							<InputElement
								name={`organismName-${productSubscribed.id}`}
								label={I18n.asset.component.form.payment_methods.organism_name}
								hidden={hidden}
								type={EInputType.TEXT}
								defaultValue={productSubscribed.organismName ?? undefined}
								readonly={readonly}
							/>
							<RadioInputElement
								name={`nantieParts-${productSubscribed.id}`}
								label={I18n.asset.component.form.payment_methods.nantie_parts}
								hidden={hidden}
								defaultValue={productSubscribed.nantieParts?.toString()}
								readonly={readonly}
								options={[
									{
										value: "true",
										label: "Oui",
									},
									{
										value: "false",
										label: "Non",
									},
								]}
							/>
							<InputElement
								name={`loanAmount-${productSubscribed.id}`}
								label={I18n.asset.component.form.payment_methods.loan_amount}
								hidden={hidden}
								type={EInputType.NUMBER}
								defaultValue={productSubscribed.loanAmount ?? undefined}
								readonly={readonly}
							/>
						</div>
					);

				default:
					return null;
			}
		},
		[productSubscribed, value, downloadRib, useSameRib, isFirstPrelevement, hidden, onTitularyNameChange, readonly, onBankDomiciliationChange, onIbanChange, onBicChange],
	);

	return (
		<>
			<div className={classes["root"]}>
				<div className={classNames(className, classes["container"], hidden && classes["hidden"])}>
					<div className={classes["label-container"]}>
						<Typography typo={ITypo.P_LARGE_BOLD} color={ITypoColor.WILD_SAND_950}>
							{productSubscribed.productSheet?.name}
						</Typography>

						<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
							{I18n.asset.component.form.payment_methods.description}
							{value && I18n.asset.enums.EPaymentMethod[value]}
						</Typography>
					</div>
					<div key={`paymentMethod-${productSubscribed.id}-${productSubscribed.paymentMethod}`} className={classes["radio-element"]}>
						<div className={classes["input-container"]}>
							<input
								id={`paymentMethod-${productSubscribed.id}-${productSubscribed.paymentMethod}`}
								type="radio"
								name={`paymentMethod-${productSubscribed.id}`}
								value={productSubscribed.paymentMethod}
								defaultChecked
								onChange={onChange}
								disabled={readonly}
								hidden={hidden}
							/>
							<label htmlFor={`paymentMethod-${productSubscribed.id}-${productSubscribed.paymentMethod}`}>
								<Typography className={classes["text"]} typo={ITypo.P_MEDIUM}>
									{I18n.asset.enums.EPaymentMethod[productSubscribed.paymentMethod]}
								</Typography>
							</label>
						</div>
						{getContent(productSubscribed.paymentMethod)}
					</div>
				</div>

				{productSubscribed.paymentMethod === EPaymentMethod.PRELEVEMENT && isFirstPrelevement && (
					<div className={classes["use-same-rib-container"]}>
						<CheckboxesInputElement
							name="use-same-rib"
							options={[
								{
									label: "Utiliser le même RIB pour tous les prélèvements",
									value: "true",
									defaultChecked: true,
								},
							]}
							onChange={onUseSameRibChange}
							readonly={readonly}
						/>
					</div>
				)}
			</div>
		</>
	);
}
