import { ArrowLongRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import { IPagination } from "components/elements/InfiniteScroll";
import Modal from "components/elements/Modal";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import useHead from "hooks/useHead";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SubscriptionService from "services/SubscriptionService";
import * as P from "ts-pattern";
import { container } from "tsyringe";

import { getSubscriberIdentity } from "../elements/SubscriptionsTable";
import classes from "./classes.module.scss";

const subscriptionService = container.resolve(SubscriptionService);
const configService = container.resolve(ApplicationConfig);
const modules = container.resolve(ModuleConfig).get().modules;

export default function InProgress() {
	useHead({
		title: `${I18n.asset.pages.subscriptions.page_title} - ${I18n.asset.pages.subscriptions.sub_pages.in_progress.page_title}`,
	});

	const [rows, setRows] = useState<IRowProps[]>([]);
	const { isOpen, close, open } = useOpenable();
	const [subscriptionIdToDelete, setSubscriptionIdToDelete] = useState<string | null>(null);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const openModal = useCallback(
		(subscriptionId: string) => {
			open();
			setSubscriptionIdToDelete(subscriptionId);
		},
		[open],
	);

	const closeModal = useCallback(() => {
		close();
		setSubscriptionIdToDelete(null);
	}, [close]);

	const fetchData = useCallback(
		async () =>
			subscriptionService.getAllDrafts(pagination.current, search.current).then((data) => {
				if (data.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(data, openModal)]);
				pagination.current.skip += pagination.current.take;
				return data;
			}),
		[openModal],
	);

	useEffect(() => {
		subscriptionService.countDrafts().then(setCount);
	}, []);

	const onNext = useCallback(
		(release: () => void) => {
			fetchData().then((data) => {
				if (!data.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchData],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	const deleteSubscription = useCallback(async () => {
		if (!subscriptionIdToDelete) return console.error("No subscription Id To Delete");
		await subscriptionService
			.deleteDraft(subscriptionIdToDelete)
			.then(() => setRows((_rows) => _rows.filter((row) => row.rowKey !== subscriptionIdToDelete)))
			.then(close)
			.catch((error) => {
				console.error("Error Subscription not deleted >", error);
			});
	}, [subscriptionIdToDelete, close]);

	return (
		<div className={classes["root"]}>
			<Table columnsHead={columnsHead} count={count.count} rows={rows} placeholderSearchbar="Chercher une souscription" onNext={onNext} onSearch={onSearch} />
			<Modal isOpen={isOpen} onClose={closeModal} className={classes["delete-modal"]}>
				<Typography typo={ITypo.H1}>{I18n.asset.pages.subscriptions.sub_pages.in_progress.modal.title}</Typography>

				<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.subscriptions.sub_pages.in_progress.modal.irreversible}</Typography>

				<div className={classes["button-container"]}>
					<Button onClick={closeModal} variant={EButtonVariant.OUTLINED}>
						{I18n.asset.common.cancel}
					</Button>
					<Button onClick={deleteSubscription}>{I18n.asset.common.delete}</Button>
				</div>
			</Modal>
		</div>
	);
}

function buildRows(subscriptions: SubscriptionResponseResource[], openModal: (subscriptionId: string) => void): IRowProps[] {
	return subscriptions.map((subscription) => {
		const productSubscribed = subscription.productsSubscribed?.find((product) => product) ?? null;
		return {
			rowKey: subscription.id,
			subscriber: getSubscriberIdentity(productSubscribed),
			type: I18n.asset.enums.ESubscriptionType[subscription.subscriptionType],
			number_of_products: subscription.productsSubscribed!.length,
			amount_subscribed: subscription.productsSubscribed!.reduce((acc, product) => acc + product.quantity * product.productSheet!.price, 0),
			continue: (
				<Link to={getConsultLink(subscription)}>
					<Button>
						{I18n.trslt(I18n.asset.common.back_to)}
						<ArrowLongRightIcon className={classes["icon"]} />
					</Button>
				</Link>
			),
			delete: <TrashIcon className={classes["delete-icon"]} onClick={() => openModal(subscription.id)} />,
		};
	});
}

function getConsultLink(subscription: SubscriptionResponseResource) {
	return P.match(subscription.subscriptionType)
		.with(ESubscriptionType.naturalPerson, () => modules.pages.Subscriptions.props.pages.SubscriptionQuestions.props.path.replace(":subscriptionId", subscription.id))
		.with(ESubscriptionType.legalPerson, () =>
			modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.PaymentMethods.props.path.replace(":subscriptionId", subscription.id),
		)
		.with(ESubscriptionType.coSubscriptionNaturalPerson, () =>
			modules.pages.Subscriptions.props.pages.SubscriptionQuestions.props.path.replace(":subscriptionId", subscription.id),
		)
		.otherwise(() => "");
}

const columnsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.subscriptions.sub_pages.in_progress.subscriber,
		key: "subscriber",
	},
	{
		headContent: I18n.asset.pages.subscriptions.sub_pages.in_progress.type,
		key: "type",
	},
	{
		headContent: I18n.asset.pages.subscriptions.sub_pages.in_progress.number_of_products,
		key: "number_of_products",
	},
	{
		headContent: I18n.asset.pages.subscriptions.sub_pages.in_progress.amount_subscribed,
		formatType: ECellType.EURO,
		key: "amount_subscribed",
	},
	{
		headContent: "",
		key: "continue",
		widthPercentage: 5,
	},
	{
		headContent: "",
		key: "delete",
		widthPercentage: 5,
	},
];
