import { ECountry } from "common/enums/Country/ECountry";

export const ENationalityImplementation: Record<ECountry, string> = {
	afghanistan: "Afghan",
	afrique_du_sud: "Sud-Africain",
	albanie: "Albanais",
	algerie: "Algérien",
	allemagne: "Allemand",
	andorre: "Andorran",
	angola: "Angolais",
	antigua_et_barbuda: "Antiguais et Barbudien",
	arabie_saoudite: "Saoudien",
	argentine: "Argentin",
	armenie: "Arménien",
	australie: "Australien",
	autriche: "Autrichien",
	azerbaidjan: "Azerbaïdjanais",
	bahamas: "Bahaméen",
	bahrein: "Bahreïni",
	bangladesh: "Bangladais",
	barbade: "Barbadien",
	bielorussie: "Biélorusse",
	belgique: "Belge",
	belize: "Bélizien",
	benin: "Béninois",
	bhoutan: "Bhoutanais",
	bolivie: "Bolivien",
	bosnie_herzegovine: "Bosnien et Herzégovinien",
	botswana: "Botswanais",
	bresil: "Brésilien",
	brunei: "Brunéien",
	bulgarie: "Bulgare",
	burkina_faso: "Burkinabè",
	burundi: "Burundais",
	cambodge: "Cambodgien",
	cameroun: "Camerounais",
	canada: "Canadien",
	cap_vert: "Cap-verdien",
	republique_centrafricaine: "Centrafricain",
	chili: "Chilien",
	chine: "Chinois",
	chypre: "Chypriote",
	colombie: "Colombien",
	comores: "Comorien",
	republique_du_congo: "Congolais",
	republique_democratique_du_congo: "Congolais de la RDC",
	coree_du_sud: "Sud-Coréen",
	coree_du_nord: "Nord-Coréen",
	costa_rica: "Costaricain",
	cote_d_ivoire: "Ivoirien",
	croatie: "Croate",
	cuba: "Cubain",
	danemark: "Danois",
	djibouti: "Djiboutien",
	republique_dominicaine: "Dominicain",
	dominique: "Dominiquais",
	egypte: "Égyptien",
	emirats_arabes_unis: "Émirati",
	equateur: "Équatorien",
	erythree: "Érythréen",
	espagne: "Espagnol",
	estonie: "Estonien",
	etats_unis: "Américain",
	ethiopie: "Éthiopien",
	fidji: "Fidjien",
	finlande: "Finlandais",
	france: "Français",
	gabon: "Gabonais",
	gambie: "Gambien",
	georgie: "Géorgien",
	ghana: "Ghanéen",
	grece: "Grec",
	grenade: "Grenadien",
	guatemala: "Guatémaltèque",
	guinee: "Guinéen",
	guinee_bissau: "Bissau-Guinéen",
	guinee_equatoriale: "Équato-guinéen",
	guyana: "Guyanais",
	haiti: "Haïtien",
	honduras: "Hondurien",
	hongrie: "Hongrois",
	inde: "Indien",
	indonesie: "Indonésien",
	iran: "Iranien",
	irak: "Irakien",
	irlande: "Irlandais",
	islande: "Islandais",
	israel: "Israélien",
	italie: "Italien",
	jamaique: "Jamaïcain",
	japon: "Japonais",
	jordanie: "Jordanien",
	kazakhstan: "Kazakh",
	kenya: "Kényan",
	kirghizistan: "Kirghize",
	kiribati: "Kiribatien",
	koweit: "Koweïtien",
	laos: "Laotien",
	lesotho: "Lesothan",
	lettonie: "Letton",
	liban: "Libanais",
	liberia: "Libérien",
	libye: "Libyen",
	liechtenstein: "Liechtensteinois",
	lituanie: "Lituanien",
	luxembourg: "Luxembourgeois",
	macedoine_du_nord: "Macédonien",
	madagascar: "Malgache",
	malaisie: "Malaisien",
	malawi: "Malawien",
	maldives: "Maldivien",
	mali: "Malien",
	malte: "Maltais",
	maroc: "Marocain",
	iles_marshall: "Marshallais",
	maurice: "Mauricien",
	mauritanie: "Mauritanien",
	mexique: "Mexicain",
	etats_federes_de_micronesie: "Micronésien",
	moldavie: "Moldave",
	monaco: "Monégasque",
	mongolie: "Mongol",
	montenegro: "Monténégrin",
	mozambique: "Mozambicain",
	birmanie: "Birman",
	namibie: "Namibien",
	nauru: "Nauruan",
	nepal: "Népalais",
	nicaragua: "Nicaraguayen",
	niger: "Nigérien",
	nigeria: "Nigérian",
	norvege: "Norvégien",
	nouvelle_zelande: "Néo-Zélandais",
	nouvelle_caledonie: "Néo-Calédonien",
	oman: "Omanais",
	ouganda: "Ougandais",
	ouzbekistan: "Ouzbek",
	pakistan: "Pakistanais",
	palaos: "Paluan",
	panama: "Panaméen",
	papouasie_nouvelle_guinee: "Papouasien-Néo-Guinéen",
	paraguay: "Paraguayen",
	pays_bas: "Néerlandais",
	perou: "Péruvien",
	philippines: "Philippin",
	pologne: "Polonais",
	portugal: "Portugais",
	qatar: "Qatari",
	roumanie: "Roumain",
	royaume_uni: "Britannique",
	russie: "Russe",
	rwanda: "Rwandais",
	saint_christophe_et_nieves: "Kittitien et Névicien",
	saint_marin: "Saint-Marinais",
	saint_vincent_et_les_grenadines: "Vincentais",
	sainte_lucie: "Saint-Lucien",
	iles_salomon: "Salomonais",
	salvador: "Salvadorien",
	samoa: "Samoan",
	sao_tome_et_principe: "Santoméen",
	senegal: "Sénégalais",
	serbie: "Serbe",
	seychelles: "Seychellois",
	sierra_leone: "Sierra-Léonais",
	singapour: "Singapourien",
	slovaquie: "Slovaque",
	slovenie: "Slovène",
	somalie: "Somalien",
	soudan: "Soudanais",
	soudan_du_sud: "Sud-Soudanais",
	sri_lanka: "Sri-Lankais",
	suede: "Suédois",
	suisse: "Suisse",
	suriname: "Surinamais",
	eswatini: "Swazi",
	syrie: "Syrien",
	tadjikistan: "Tadjik",
	tanzanie: "Tanzanien",
	tchad: "Tchadien",
	tchequie: "Tchèque",
	thailande: "Thaïlandais",
	timor_oriental: "Timorais",
	togo: "Togolais",
	tonga: "Tongien",
	trinite_et_tobago: "Trinidadien et Tobagonien",
	tunisie: "Tunisien",
	turkmenistan: "Turkmène",
	turquie: "Turc",
	tuvalu: "Tuvaluan",
	ukraine: "Ukrainien",
	uruguay: "Uruguayen",
	vanuatu: "Vanuatais",
	venezuela: "Vénézuélien",
	viet_nam: "Vietnamien",
	yemen: "Yéménite",
	zambie: "Zambien",
	zimbabwe: "Zimbabwéen",
};
