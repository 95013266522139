"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAssociationProfesionnelle = void 0;
exports.EAssociationProfesionnelle = {
    CNCGP: "CNCGP",
    CNCEF: "CNCEF",
    ANACOFI_CIF: "ANACOFI_CIF",
    LA_COMPAGNIE_DES_CGP_CIF: "LA_COMPAGNIE_DES_CGP_CIF",
    AUCUNE_ASSOCIATION: "Aucune_association",
};
