import AdminProductSubscribedApi from "api/wenImmo/admin/ProductSubscribedApi";
import ProductSubscribedApi from "api/wenImmo/ProductSubscribedApi";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSubscribedServiceClassToken from "common/resources/injectables/ProductSubscribedServiceClassToken";
import ProductSubscribedToFileAdminRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileAdminRequestResource";
import ProductSubscribedToFileResponseResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileResponseResource";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscriptionUpdateResource from "common/resources/ProductSubscribed/ProductSubscriptionUpdateResource";
import { IPagination } from "components/elements/InfiniteScroll";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class ProductSubscribedService implements ProductSubscribedServiceClassToken {
	private api: ProductSubscribedApi = container.resolve(ProductSubscribedApi);
	private adminApi: AdminProductSubscribedApi = container.resolve(AdminProductSubscribedApi);

	public async exists(uid: string) {
		return !!this.api.getByUid(uid).catch(toValidationError);
	}

	public async countBySteps(steps: ESubStep[]) {
		return this.api.countBySteps(steps).catch(toValidationError);
	}

	public async countByStepsAdmin(steps: ESubStep[]) {
		return this.adminApi.countBySteps(steps).catch(toValidationError);
	}

	public async getAllByStep(steps: ESubStep[], pagination: IPagination, search?: string | null): Promise<ProductSubscribedResponseResource[]> {
		return this.api.getAllByStep(steps, pagination, search).catch(toValidationError);
	}

	public async getByUid(uid: string): Promise<ProductSubscribedResponseResource> {
		return this.api.getByUid(uid).catch(toValidationError);
	}

	public async getAllByStepAdmin(steps: ESubStep[], pagination: IPagination, search?: string | null): Promise<ProductSubscribedResponseResource[]> {
		return this.adminApi.getAllByStep(steps, pagination, search).catch(toValidationError);
	}

	public async updateStatus(uid: string, body: ProductSubscriptionUpdateResource): Promise<ProductSubscribedResponseResource> {
		return this.adminApi.updateStatus(uid, body).catch(toValidationError);
	}

	public async uploadFileAdmin(file: File): Promise<FileResponseResource> {
		return this.adminApi.uploadFile(file).catch(toValidationError);
	}

	public async attachFile(attachedFile: ProductSubscribedToFileAdminRequestResource): Promise<ProductSubscribedToFileResponseResource> {
		return this.adminApi.attachFile(attachedFile).catch(toValidationError);
	}

	public async deleteFile(fileUid: string) {
		return this.adminApi.deleteFile(fileUid).catch(toValidationError);
	}

	public async getAllValidatedByClientId(clientId: string, pagination: IPagination, search?: string | null): Promise<ProductSubscribedResponseResource[]> {
		return this.api.getAllValidatedByClientId(clientId, pagination, search).catch(toValidationError);
	}

	public async getAllValidatedByCorporationId(corporationId: string, pagination: IPagination, search?: string | null): Promise<ProductSubscribedResponseResource[]> {
		return this.api.getAllValidatedByCorporationId(corporationId, pagination, search).catch(toValidationError);
	}

	public buildFileUrlByFileId(fileId: string) {
		return this.api.buildFileUrlByFileId(fileId);
	}

	public buildFileUrlByFileIdAdmin(fileId: string) {
		return this.adminApi.buildFileUrlByFileId(fileId);
	}

	public async getSignableDocumentsTypes(productSubscribedId: string): Promise<EProductDocumentType[]> {
		return this.adminApi.getSignableDocumentsTypes(productSubscribedId).catch(toValidationError);
	}

	public buildSignableDocumentUrl(productSubscribedId: string, docType: EProductDocumentType) {
		return this.adminApi.buildSignableDocumentUrl(productSubscribedId, docType);
	}

	public buildSignableDocumentsZipUrl(productSubscribedId: string){
		return this.adminApi.buildSignableDocumentsZipUrl(productSubscribedId);
	}

}
