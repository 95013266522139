import { ClipboardDocumentIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Info from "components/elements/Icons/Info";
import TooltipElement from "components/elements/TooltipElement";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";

export enum EInputType {
	TEXT = "text",
	PASSWORD = "password",
	EMAIL = "email",
	HIDDEN = "hidden",
	NUMBER = "number",
}

export type IProps = {
	name: string;
	type: EInputType;
	placeholder?: string;
	className?: string;
	label?: string;
	caption?: string;
	autoComplete?: "given-name" | "family-name" | "email" | "new-password" | "current-password" | "off" | "on" | "username";
	defaultValue?: string | number | readonly string[] | null;
	readonly?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hidden?: boolean;
	isNumericString?: {
		allowSymbols?: boolean;
	};
	min?: number;
	max?: number;
	canCopy?: boolean;
	forceDisplayErrors?: boolean;
	format?: "iban";
	tooltipText?: string;
	size?: "small" | "medium";
	step?: number;
};

export default function InputElement(props: IProps) {
	const {
		label,
		name,
		type,
		placeholder,
		className,
		caption,
		autoComplete = "off",
		defaultValue,
		readonly,
		min,
		hidden,
		isNumericString,
		onChange,
		canCopy = false,
		forceDisplayErrors = false,
		format,
		tooltipText,
		size = "medium",
		step,
	} = props;
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(name);
	const hasErrors = errors?.length > 0;

	const [value, setValue] = useState(defaultValue?.toString() || "");

	const onWheel = useCallback((event: React.WheelEvent<HTMLInputElement>) => {
		(event.target as HTMLInputElement).blur();
	}, []);

	useEffect(() => {
		setValue(defaultValue?.toString() || "");
	}, [defaultValue]);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const inputValue = event.target.value;
			const regex = isNumericString?.allowSymbols ? /^[0-9+\-.]*$/ : /^[0-9]*$/;
			let valueToSet = inputValue;

			if (format === "iban") {
				//add space every 4 characters from the beginning
				valueToSet = inputValue
					.replace(/\s/g, "")
					.replace(/(.{4})/g, "$1 ")
					.trim();
			}

			if (isNumericString && !regex.test(inputValue)) return;

			if (props.min !== undefined && parseFloat(inputValue) < props.min) valueToSet = props.min.toString();
			if (props.max !== undefined && parseFloat(inputValue) > props.max) valueToSet = props.max.toString();
			setValue(valueToSet);
			event.target.value = valueToSet;
			onChange && onChange(event);
		},
		[format, isNumericString, onChange, props.max, props.min],
	);

	const copyContent = useCallback(() => {
		if (canCopy) {
			toast.success("Texte copié dans le presse-papier");

			navigator.clipboard.writeText(value);
		}
	}, [canCopy, value]);

	return (
		<div className={classNames(className, classes["root"], hidden && !forceDisplayErrors && classes["hidden"])}>
			{label && !hidden && (
				<div className={classes["label-container"]}>
					<Typography style={{ alignSelf: "flex-start" }} typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
						{label}
					</Typography>
					{tooltipText && (
						<TooltipElement title={props.tooltipText}>
							<InformationCircleIcon height={24} width={24} style={{ cursor: "help" }} />
						</TooltipElement>
					)}
				</div>
			)}
			<div className={classNames(classes["input-container"], hidden && classes["hidden"])}>
				<input
					className={classNames(classes["input-element"], props.readonly && classes["readonly"], size && classes[size])}
					name={name}
					type={type}
					placeholder={placeholder}
					data-has-error={hasErrors.toString()}
					autoComplete={autoComplete}
					value={value}
					readOnly={readonly}
					hidden={hidden}
					onChange={handleInputChange}
					onClick={copyContent}
					onWheel={onWheel}
					min={min}
					step={step}
				/>
				{canCopy && (
					<div className={classes["clipboard-container"]}>
						<ClipboardDocumentIcon className={classes["clipboard-icon"]} />
					</div>
				)}
			</div>
			{caption && !hidden && (
				<Typography typo={ITypo.CAPTION} color={hasErrors ? ITypoColor.ERROR_500 : ITypoColor.WILD_SAND_900}>
					{caption}
				</Typography>
			)}
			{hasErrors && (!hidden || forceDisplayErrors) && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
