"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EProductDocumentType = void 0;
exports.EProductDocumentType = {
    bulletin_souscription: "bulletin_souscription",
    mandat_sepa: "mandat_sepa",
    questionnaire_investisseur: "questionnaire_investisseur",
    origine_fonds: "origine_fonds",
    accord_conjoint_versement: "accord_conjoint_versement",
    appor_compte_courant_associe: "appor_compte_courant_associe",
    remploi_fonds_propres: "remploi_fonds_propres",
    rapport_special: "rapport_special",
    forumulaire_facta: "forumulaire_facta",
};
