import { ClockIcon, EyeIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Alert } from "@mui/material";
import classNames from "classnames";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import I18n from "components/materials/I18n";
import React, { useCallback, useEffect, useMemo } from "react";

import Button, { EButtonVariant } from "../Button";
import TooltipElement from "../TooltipElement";
import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";

export enum EFileTypes {
	PDF = "application/pdf",
	JPEG = "image/jpeg",
	PNG = "image/png",
	JPG = "image/jpg",
}

type IProps = {
	title?: string;
	onChange?: (files: File[]) => void;
	fileType: ESubFilesTypes | EProductFileType | EProductDocumentType;
	defaultFiles?: FileResponseResource[];
	uploadFile?: (file: File, fileType: ESubFilesTypes | EProductFileType | EProductDocumentType) => Promise<FileResponseResource | void>;
	onFileDeleted?: (uid: string) => void;
	tooltipText?: string;
	filesAccepted?: EFileTypes[];
	cannotDelete?: boolean;
	onClickViewer?: () => void;
	onClickHistory?: () => void;
};

type IFileAccepted = {
	extension: string;
	size: number;
};

const defaultFilesAccepted: Record<EFileTypes, IFileAccepted> = {
	"application/pdf": {
		extension: "pdf",
		size: 41943040,
	},
	"image/jpeg": {
		extension: "jpeg",
		size: 41943040,
	},
	"image/png": {
		extension: "png",
		size: 41943040,
	},
	"image/jpg": {
		extension: "jpg",
		size: 41943040,
	},
};

export default function FileUploaderComponent(props: Readonly<IProps>) {
	const inputRef = React.createRef<HTMLInputElement>();
	const [files, setFiles] = React.useState<FileResponseResource[]>(props.defaultFiles ?? []);
	const [isDragOver, setIsDragOver] = React.useState<boolean>(false);

	const [showFailedUploaded, setShowFailedUploaded] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);

	useEffect(() => {
		setFiles(props.defaultFiles ?? []);
	}, [props.defaultFiles]);

	const filesAccepted = useMemo(() => {
		if (!props.filesAccepted || props.filesAccepted?.length < 0) return defaultFilesAccepted;
		const filesAccepted: { [key: string]: IFileAccepted } = {};
		props.filesAccepted.forEach((file) => {
			filesAccepted[file] = defaultFilesAccepted[file];
		});
		return filesAccepted;
	}, [props.filesAccepted]);

	/**
	 * @description Generic upload file in db and S3
	 */
	const uploadFile = useCallback(
		async (file: File) => {
			setLoading(true);
			props.uploadFile &&
				props.uploadFile(file, props.fileType).then((file) => {
					if (!file) return;
					setFiles([...files, file]);
				});
			setLoading(false);
		},
		[props, files],
	);

	/**
	 * @description Generic delete file from db and S3
	 */
	const removeFile = useCallback(
		async (fileIdToRemove: string) => {
			if (!fileIdToRemove) return;
			setLoading(true);
			files.filter((file) => file.id !== fileIdToRemove);
			props.onFileDeleted && props.onFileDeleted(fileIdToRemove);
			setLoading(false);
		},
		[files, props],
	);

	const onCloseAlertUpload = useCallback(() => {
		setShowFailedUploaded(null);
	}, [setShowFailedUploaded]);

	// this method should link the file to the current productSubscribed
	const addDocument = useCallback(() => {
		if (!inputRef.current) return;
		inputRef.current.value = "";
		inputRef.current.click();
	}, [inputRef]);

	const onUploadSuccess = useCallback(() => {
		console.info("Document envoyé avec succès");
	}, []);

	const shortName = useCallback((name: string): string => {
		const maxLength = 20;
		if (name.length > maxLength) {
			return name.substring(0, maxLength / 2) + "..." + name.substring(name.length - maxLength / 2, name.length);
		}
		return name;
	}, []);

	const onDragOver = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			if (!isDragOver) setIsDragOver(true);
			event.preventDefault();
		},
		[isDragOver],
	);

	const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		setIsDragOver(false);
		event.preventDefault();
	}, []);

	const addFile = useCallback(
		async (file: File) => {
			const fileAccepted = filesAccepted[file.type as EFileTypes];
			if (!fileAccepted) {
				alert("Ce type de fichier n'est pas accepté");
				return false;
			}
			if (file.size > fileAccepted.size) {
				alert("Ce fichier est trop volumineux");
				return false;
			}
			setLoading(true);
			try {
				await uploadFile(file);
			} catch (e) {
				setShowFailedUploaded("Le fichier ne correspond pas aux critères demandés");
				setLoading(false);
				return false;
			}
			if (!files) return;

			onUploadSuccess();
			return true;
		},
		[filesAccepted, files, onUploadSuccess, uploadFile],
	);

	const onDragDrop = useCallback(
		async (event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();
			setIsDragOver(false);
			const file = event.dataTransfer.files[0];
			if (file) await addFile(file);
		},
		[addFile],
	);

	const onFileChange = useCallback(() => {
		if (!inputRef.current) return;
		const files = inputRef.current.files;
		if (!files) {
			setLoading(false);
			return;
		}
		const file = files[0];

		try {
			if (file) {
				setLoading(true);
				addFile(file);
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	}, [inputRef, addFile]);

	return (
		<div className={classes["container"]}>
			<div className={classes["title-container"]}>
				<Typography typo={ITypo.P_MEDIUM} className={classes["title"]}>
					{props.title}
				</Typography>
				<div className={classes["icon-container"]}>
					{props.tooltipText && (
						<TooltipElement title={props.tooltipText}>
							<InformationCircleIcon height={24} width={24} style={{ cursor: "help" }} />
						</TooltipElement>
					)}
					{props.onClickHistory && <ClockIcon onClick={props.onClickHistory} height={20} width={20} />}
					{props.onClickViewer && <EyeIcon onClick={props.onClickViewer} height={20} width={20} />}
				</div>
			</div>
			<div
				className={classNames(classes["root"], files.length !== 0 && classes["uploaded"])}
				onDragOver={onDragOver}
				onDrop={onDragDrop}
				onDragLeave={onDragLeave}
				data-drag-over={isDragOver.toString()}>
				<input type="file" ref={inputRef} hidden onChange={onFileChange} accept={Object.keys(filesAccepted).join(",")} />
				{files.length !== 0 && (
					<div className={classes["files-name-container"]}>
						{files.map((file) => {
							return (
								<div className={classes["file-name-container"]} key={file.id}>
									<Typography typo={ITypo.P_MEDIUM} title={file.name} className={classes["left-part"]}>
										{shortName(file.name)}
									</Typography>
									{!props.cannotDelete && <XMarkIcon className={classes["cross"]} onClick={() => removeFile(file.id)} />}
								</div>
							);
						})}
					</div>
				)}

				<div className={classes["bottom-container"]}>
					<Button variant={EButtonVariant.OUTLINED} className={classes["add-button"]} onClick={addDocument}>
						{I18n.asset.component.deposit_document.deposit_documents}
					</Button>
				</div>
				{loading === false && <Typography typo={ITypo.P_MEDIUM}>{I18n.asset.component.deposit_document.or_drag_drop}</Typography>}
				{loading && (
					<div className={classes["file-container"]}>
						<div className={classes["left-part"]}>
							<Typography typo={ITypo.P_MEDIUM}>{I18n.asset.common.loading}</Typography>
						</div>
					</div>
				)}
			</div>
			{showFailedUploaded && (
				<Alert onClose={onCloseAlertUpload}>
					<div className={classes["modal-content"]}>
						<Typography typo={ITypo.P_MEDIUM} className={classes["text"]}>
							{I18n.asset.component.deposit_document.file_not_autorized}
						</Typography>
						<Typography typo={ITypo.P_MEDIUM} className={classes["text"]}>
							{showFailedUploaded}
						</Typography>
					</div>
				</Alert>
			)}
		</div>
	);
}
