import { PowerIcon, TicketIcon, UserCircleIcon, UsersIcon, TagIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "assets/images/logo243x99.svg";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import ModuleConfig from "configs/ModuleConfig";
import { AuthContext } from "contexts/Auth";
import { UserContext } from "contexts/User";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { container } from "tsyringe";

import I18n from "../I18n";
import classes from "./classes.module.scss";
import MenuLink from "./MenuLink";
import HasRules from "../HasRules";
import ModulePage from "../ModulePage";

const pages = container.resolve(ModuleConfig).get().modules.pages;

export default function NavMenu() {
	const authContext = useContext(AuthContext);
	const { user } = useContext(UserContext);
	function getAllNestedPaths(page: any): string[] {
		let paths: string[] = [];
		if (page.props.path) {
			paths.push(page.props.path);
		}
		if (page.props.pages) {
			for (const nestedPage of Object.values(page.props.pages)) {
				paths = paths.concat(getAllNestedPaths(nestedPage));
			}
		}
		return paths;
	}

	return (
		<div className={classes["root"]}>
			<Link to={pages.Products.props.path}>
				<Logo />
			</Link>
			<div className={classes["container"]}>
				<nav>
					<HasRules requiredRules={{ OR: { access_platform__cgp: true, access_platform__middle_office: true } }}>
						<ModulePage from={pages.Clients}>
							<MenuLink
								title={I18n.asset.pages.products.page_title}
								icon={<TagIcon />}
								to={pages.Products.props.path}
								matchingPaths={getAllNestedPaths(pages.Products)}
							/>
						</ModulePage>
					</HasRules>

					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }}>
						<ModulePage from={pages.Subscriptions}>
							<MenuLink
								title={I18n.asset.pages.subscriptions.page_title}
								icon={<TicketIcon />}
								to={pages.Subscriptions.props.pages.InProgress.props.path}
								matchingPaths={getAllNestedPaths(pages.Subscriptions)}
							/>
						</ModulePage>
					</HasRules>

					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }}>
						<ModulePage from={pages.Clients}>
							<MenuLink
								title={I18n.asset.pages.clients.page_title}
								icon={<UsersIcon />}
								to={pages.Clients.props.pages.PhysicalPersonClients.props.path}
								matchingPaths={getAllNestedPaths(pages.Clients)}
							/>
						</ModulePage>
					</HasRules>

					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
						<ModulePage from={pages.MOSubscriptions}>
							<MenuLink
								title={I18n.asset.pages.mo_subscriptions.page_title}
								icon={<TicketIcon />}
								to={pages.MOSubscriptions.props.pages.ToProcess.props.path}
								matchingPaths={getAllNestedPaths(pages.MOSubscriptions)}
							/>
						</ModulePage>
					</HasRules>

					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
						<ModulePage from={pages.Partners}>
							<MenuLink
								title={I18n.asset.pages.partners.page_title}
								icon={<UsersIcon />}
								to={pages.Partners.props.pages.OfficesList.props.path}
								matchingPaths={getAllNestedPaths(pages.Partners)}
							/>
						</ModulePage>
					</HasRules>

					<HasRules
						requiredRules={{
							OR: {
								access_platform__cgp: true,
								access_platform__middle_office: true,
							},
						}}>
						<ModulePage from={pages.Account}>
							<MenuLink
								title={I18n.asset.pages.account.page_title}
								icon={<UserCircleIcon />}
								to={pages.Account.props.path}
								matchingPaths={getAllNestedPaths(pages.Account)}
							/>
						</ModulePage>
					</HasRules>
				</nav>
				<div className={classes["bottom"]}>
					<div className={classes["contact"]}>
						<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.BLACK}>
							{I18n.asset.component.menu.question}
						</Typography>
						<a href="mailto:backoffice@wenimmo.com">
							<Typography style={{ textDecoration: "underline" }} typo={ITypo.P_MEDIUM} color={ITypoColor.BLACK}>
								{I18n.asset.component.menu.contact}
							</Typography>
						</a>
					</div>
					{user && (
						<Button variant={EButtonVariant.CONTAINED} icon={<PowerIcon />} iconposition="left" onClick={authContext.logout} fullwidth>
							{I18n.asset.common.logout}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}
