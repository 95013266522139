import classes from "./classes.module.scss";
import { useCallback, useEffect, useState } from "react";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import InputGroup from "components/elements/InputGroup";
import I18n from "components/materials/I18n";
import Button from "components/elements/Button";
import CheckboxesInputElement, { CheckBoxesOption } from "components/materials/Form/CheckboxesInputElement";
import Form from "components/materials/Form";
import OfficeResponseResource from "common/resources/Office/OfficeResponseResource";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import { EFormeJuridique } from "common/enums/Office/EFormeJuridique";
import { ETypeConseiller } from "common/enums/Office/ETypeConseiller";
import { EHabilitation } from "common/enums/Office/EHabilitation";
import { EAssociationProfesionnelle } from "common/enums/Office/EAssociationProfesionnelle";
import { ELigneMetier } from "common/enums/Office/ELigneMetier";
import { container } from "tsyringe";
import ProductService from "services/ProductService";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "common/resources/Resource";
import OfficeUpdateRequestResource from "common/resources/Office/OfficeUpdateRequestResource";
import OfficeService from "services/OfficeService";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import FormUtils from "utils/FormUtils";

type IProps = {
	office: OfficeResponseResource;
	loadOffice: () => void;
};
const baseTrad = I18n.asset.pages.partners.pages.offices_details;
const productService = container.resolve(ProductService);
const officeService = container.resolve(OfficeService);

export default function OfficeDetailsOffice(props: IProps) {
	const { office } = props;
	const [isReadonly, setIsReadonly] = useState(true);
	const [allChecked, setAllChecked] = useState(false);
	const [checkboxes, setCheckboxes] = useState<CheckBoxesOption[]>([]);
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const [productSheets, setProductSheets] = useState<ProductSheetLightResponseResource[]>([]);
	const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (isReadonly) e.preventDefault();
		setIsReadonly(!isReadonly);
	};

	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: any }) => {
			e.preventDefault();
			setErrors([]);
			const officeResource = OfficeUpdateRequestResource.hydrate<OfficeUpdateRequestResource>({
				id: office.id,
				accountOwnerName: formData["accountOwnerName"],
				address: formData["address"],
				associationProfessionnelle: formData["associationProfessionnelle"],
				bic: formData["bic"],
				birthDateManager: new Date(formData["birthDateManager"]),
				capitalSocial: parseFloat(formData["capitalSocial"]),
				emailManager: formData["emailManager"],
				firstNameManager: formData["firstNameManager"],
				formeJuridique: formData["formeJuridique"] as EFormeJuridique,
				habilitations: FormUtils.getEnumValues<EHabilitation>(formData, "habilitations", EHabilitation),
				iban: formData["iban"],
				lastNameManager: formData["lastNameManager"],
				ligneMetiers: FormUtils.getEnumValues<ELigneMetier>(formData, "ligneMetiers", ELigneMetier),
				numeroOrias: formData["numeroOrias"],
				products: checkboxes.filter((option) => option.checked).map((option) => ({ id: option.value })),
				raisonSociale: formData["raisonSociale"],
				rcs: formData["rcs"],
				siret: formData["siret"],
				typeConseiller: formData["typeConseiller"] as ETypeConseiller,
				emailAdminitratif: formData["emailAdminitratif"],
				phoneNumber: formData["phoneNumber"],
			});
			officeResource
				.validateOrReject()
				.then((resource) => officeService.update(office.id, resource))
				.then(() => {
					setIsReadonly(true);
					setErrors([]);
					props.loadOffice();
				})
				.catch((error: unknown) => {
					console.warn(error);
					setIsReadonly(false);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[checkboxes, office.id, props],
	);

	const onProductChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value, checked } = event.target;
			if (value === "all") {
				setAllChecked(checked);
				setCheckboxes(checkboxes.map((option) => ({ ...option, checked })));
			} else {
				setCheckboxes(checkboxes.map((option) => (option.value === value ? { ...option, checked } : option)));
				const newCheckboxesState = checkboxes.map((option) => (option.value === value ? { ...option, checked } : option));
				const allOtherChecked = newCheckboxesState.every((option) => option.checked);
				setAllChecked(allOtherChecked);
			}
		},
		[checkboxes],
	);

	useEffect(() => {
		productService.getAdminLastProductSheets().then(setProductSheets);
	}, []);

	useEffect(() => {
		if (productSheets.length === 0 || !office.products) return;
		if (productSheets.length === office.products.length) setAllChecked(true);
		setCheckboxes(
			productSheets
				.map((productSheet) => ({
					value: productSheet.productId,
					label: productSheet.name,
					checked: office.products!.find((product) => product.id === productSheet.productId) ? true : false,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		);
	}, [office.products, productSheets]);

	return (
		<div className={classes["root"]}>
			<Form className={classes["form-container"]} onSubmit={onSubmit} errors={errors}>
				<div className={classes["row"]}>
					<div className={classes["col"]}>
						<InputGroup title={"Identité juridique"}>
							<InputElement
								name={"raisonSociale"}
								type={EInputType.TEXT}
								defaultValue={office.raisonSociale}
								readonly={isReadonly}
								label={baseTrad.identite_juridique.company_name}
							/>
							<InputElement name={"siret"} type={EInputType.TEXT} defaultValue={office.siret} readonly={isReadonly} label={baseTrad.identite_juridique.siret} />
							<MuiSelectInputElement
								name={"formeJuridique"}
								label={baseTrad.identite_juridique.forme_juridique}
								options={Object.keys(EFormeJuridique).map((key) => ({
									label: I18n.asset.enums.EFormeJuridique[EFormeJuridique[key as keyof typeof EFormeJuridique]],
									id: EFormeJuridique[key as keyof typeof EFormeJuridique],
								}))}
								defaultValue={{
									label: I18n.asset.enums.EFormeJuridique[office.formeJuridique],
									id: office.formeJuridique,
								}}
								readonly={isReadonly}
							/>
							<InputElement name={"rcs"} type={EInputType.TEXT} defaultValue={office.rcs} readonly={isReadonly} label={baseTrad.identite_juridique.rcs} />
							<InputElement
								name={"capitalSocial"}
								type={EInputType.NUMBER}
								defaultValue={office.capitalSocial}
								readonly={isReadonly}
								label={baseTrad.identite_juridique.capital_social}
							/>
						</InputGroup>
						<InputGroup title={baseTrad.habilitations.title}>
							<MuiSelectInputElement
								name="typeConseiller"
								options={Object.keys(ETypeConseiller).map((key) => ({
									id: ETypeConseiller[key as keyof typeof ETypeConseiller],
									label: I18n.asset.enums.ETypeConseiller[ETypeConseiller[key as keyof typeof ETypeConseiller]],
								}))}
								defaultValue={{
									id: office.typeConseiller,
									label: I18n.asset.enums.ETypeConseiller[office.typeConseiller],
								}}
								readonly={isReadonly}
								label={baseTrad.habilitations.type_conseiller}
							/>
							<CheckboxesInputElement
								name="habilitations"
								options={Object.keys(EHabilitation).map((key) => ({
									label: I18n.asset.enums.EHabilitation[EHabilitation[key as keyof typeof EHabilitation]],
									value: EHabilitation[key as keyof typeof EHabilitation],
									defaultChecked: office.habilitations.includes(EHabilitation[key as keyof typeof EHabilitation]),
								}))}
								readonly={isReadonly}
								label={baseTrad.habilitations.habilitations}
							/>
							<MuiSelectInputElement
								name={"associationProfessionnelle"}
								readonly={isReadonly}
								options={Object.keys(EAssociationProfesionnelle).map((key) => ({
									id: EAssociationProfesionnelle[key as keyof typeof EAssociationProfesionnelle],
									label: I18n.asset.enums.EAssociationProfesionnelle[EAssociationProfesionnelle[key as keyof typeof EAssociationProfesionnelle]],
								}))}
								defaultValue={
									office.associationProfessionnelle && {
										id: office.associationProfessionnelle ?? "",
										label: I18n.asset.enums.EAssociationProfesionnelle[office.associationProfessionnelle as EAssociationProfesionnelle],
									}
								}
								label={baseTrad.habilitations.association_professionnelle}
							/>
							<InputElement
								name={"numeroOrias"}
								type={EInputType.TEXT}
								readonly={isReadonly}
								defaultValue={office.numeroOrias}
								label={baseTrad.habilitations.numero_orias}
							/>
							<CheckboxesInputElement
								name={"ligneMetiers"}
								readonly={isReadonly}
								options={Object.keys(ELigneMetier).map((key) => ({
									label: I18n.asset.enums.ELigneMetier[ELigneMetier[key as keyof typeof ELigneMetier]],
									value: ELigneMetier[key as keyof typeof ELigneMetier],
									defaultChecked: office.ligneMetiers.includes(ELigneMetier[key as keyof typeof ELigneMetier]),
								}))}
								label={baseTrad.habilitations.ligne_metier}
							/>
						</InputGroup>
					</div>
					<div className={classes["col"]}>
						<InputGroup title={baseTrad.gerant.title}>
							<InputElement name={"lastNameManager"} type={EInputType.TEXT} readonly={isReadonly} defaultValue={office.lastNameManager} label={baseTrad.gerant.nom} />
							<InputElement
								name={"firstNameManager"}
								type={EInputType.TEXT}
								readonly={isReadonly}
								defaultValue={office.firstNameManager}
								label={baseTrad.gerant.prenom}
							/>
							<DatePickerInputElement
								name={"birthDateManager"}
								readonly={isReadonly}
								defaultValue={office.birthDateManager}
								label={baseTrad.gerant.date_naissance}
								isAbsoluteDate
							/>
							<InputElement
								name={"emailManager"}
								type={EInputType.TEXT}
								readonly={isReadonly}
								defaultValue={office.emailManager}
								label={baseTrad.gerant.email_gerant}
							/>
						</InputGroup>
						<InputGroup title={baseTrad.coordonnes_cabinet.title}>
							<InputElement
								name={"emailAdminitratif"}
								type={EInputType.TEXT}
								readonly={isReadonly}
								defaultValue={office.emailAdminitratif}
								label={baseTrad.coordonnes_cabinet.email_administratif}
							/>
							<InputElement
								name={"phoneNumber"}
								type={EInputType.TEXT}
								readonly={isReadonly}
								defaultValue={office.phoneNumber}
								label={baseTrad.coordonnes_cabinet.numero_telephone}
							/>
							<InputElement name={"address"} type={EInputType.TEXT} readonly={isReadonly} defaultValue={office.address} label={baseTrad.coordonnes_cabinet.adresse} />
						</InputGroup>
					</div>
					<div className={classes["col"]}>
						<InputGroup title={baseTrad.donnees_bancaires.title}>
							<InputElement
								name={"accountOwnerName"}
								type={EInputType.TEXT}
								readonly={isReadonly}
								defaultValue={office.accountOwnerName}
								label={baseTrad.donnees_bancaires.titulaire_compte}
							/>
							<InputElement name={"iban"} type={EInputType.TEXT} readonly={isReadonly} defaultValue={office.iban} label={baseTrad.donnees_bancaires.iban} />
							<InputElement name={"bic"} type={EInputType.TEXT} readonly={isReadonly} defaultValue={office.bic} label={baseTrad.donnees_bancaires.bic} />
						</InputGroup>
						<InputGroup title={baseTrad.produits_cabinet.title}>
							<CheckboxesInputElement
								name="products"
								options={[
									{
										label: I18n.asset.pages.create_partner.sub_pages.create_office.check_all_spci,
										value: "all",
										checked: allChecked,
									},
									...checkboxes,
								]}
								onChange={onProductChange}
								readonly={isReadonly}
							/>
						</InputGroup>
					</div>
				</div>
				<div className={classes["row"]}>
					<div className={classes["button-container"]}>
						<Button onClick={handleClickButton} icon={<PencilSquareIcon />} iconposition="right" type="submit">
							{isReadonly ? baseTrad.buttons.edit : baseTrad.buttons.save}
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
}
