import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSubscribedToFileUserRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileUserRequestResource";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import UpdatePaymentMethodRequestResource from "common/resources/ProductSubscribed/UpdatePaymentMethodRequestResource";

import GenericAutreCgpRequestResource from "common/resources/Scpi/PhysicalPerson/GenericAutreCgpRequestResource";
import GenericConnaissanceExperienceRequestResource from "common/resources/Scpi/PhysicalPerson/GenericConnaissanceExperienceRequestResource";
import GenericFinanceDurableRequestResource from "common/resources/Scpi/PhysicalPerson/GenericFinanceDurableRequestResource";
import GenericPatrimoineRequestResource from "common/resources/Scpi/PhysicalPerson/GenericPatrimoineRequestResource";
import GenericTestAdequationRequestResource from "common/resources/Scpi/PhysicalPerson/GenericTestAdequationRequestResource";

import GenericAutreCgpRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericAutreCgpRequestResource";
import GenericConnaissanceExperienceRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericConnaissanceExperienceRequestResource";
import GenericFinanceDurableRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericFinanceDurableRequestResource";
import GenericPatrimoineRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericPatrimoineRequestResource";
import GenericTestAdequationRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericTestAdequationRequestResource";

import { GenericProduct } from "common/resources/Scpi/PhysicalPerson/GenericProduct";
import SubscriptionFileResponseResource from "common/resources/Subscription/Files/SubscriptionFileResponseResource";
import SubscriptionCreateRequestResource from "common/resources/Subscription/SubscriptionCreateRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import SubscriptionUpdateRequestResource from "common/resources/Subscription/SubscriptionUpdateRequestResource";
import { IPagination } from "components/elements/InfiniteScroll";
import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";
import SubscriptionCorporationCreateRequestResource from "common/resources/Subscription/SubscriptionCorporationCreateRequestResource";

@singleton()
export default class SubscriptionApi extends BaseApi {
	private baseurl = `${this.apiUrl}/subscriptions`;

	public async get() {
		const url = this.baseurl;
		return this.getRequest<SubscriptionResponseResource[]>(url);
	}

	public async getByUid(uid: string) {
		const url = `${this.baseurl}/${uid}`;
		return this.getRequest<SubscriptionResponseResource>(url);
	}

	public async countDrafts() {
		const url = this.baseurl.concat("/drafts/count");
		return this.getRequest<{ count: number }>(url);
	}

	public async getAllDrafts(pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search);
		const url = `${this.baseurl}/drafts?${urlParams.toString()}`;
		return this.getRequest<SubscriptionResponseResource[]>(url);
	}

	public async post(body: SubscriptionCreateRequestResource) {
		const url = this.baseurl;
		return this.postRequest<SubscriptionResponseResource>(url, {
			...body,
		});
	}

	public async postCorporation(body: SubscriptionCorporationCreateRequestResource) {
		const url = `${this.baseurl}/corporation`;
		return this.postRequest<SubscriptionResponseResource>(url, {
			...body,
		});
	}

	public async hasAllFiles(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}/has-all-files`;
		return this.getRequest<{ isValid: boolean }>(url);
	}

	public async uploadFile(subscriptionId: string, type: ESubFilesTypes, file: File) {
		const url = `${this.baseurl}/${subscriptionId}/upload-file/${type}`;
		const formadata = new FormData();
		formadata.append("file", file);
		return this.putRequestFormdata<SubscriptionFileResponseResource>(url, formadata);
	}

	public updateCategory(
		body:
			| GenericPatrimoineRequestResource
			| GenericConnaissanceExperienceRequestResource
			| GenericTestAdequationRequestResource
			| GenericFinanceDurableRequestResource
			| GenericAutreCgpRequestResource,
		id: string,
		category: keyof GenericProduct,
	) {
		const url = `${this.baseurl}/${id}/${category}`;
		return this.putRequest<SubscriptionResponseResource>(url, { ...body });
	}

	public updateCategoryCorporation(
		body:
			| GenericPatrimoineRequestResourceCorporation
			| GenericConnaissanceExperienceRequestResourceCorporation
			| GenericTestAdequationRequestResourceCorporation
			| GenericFinanceDurableRequestResourceCorporation
			| GenericAutreCgpRequestResourceCorporation,
		id: string,
		category: keyof GenericProduct,
	) {
		const url = `${this.baseurl}/${id}/${category}/corporation`;
		return this.putRequest<SubscriptionResponseResource>(url, { ...body });
	}

	public async put(body: SubscriptionUpdateRequestResource) {
		const url = this.baseurl;
		return this.putRequest<SubscriptionResponseResource>(url, {
			...body,
		});
	}

	public async updatePaymentInformations(body: UpdatePaymentMethodRequestResource) {
		const url = `${this.baseurl}/update-payment-information`;
		return this.putRequest<ProductSubscribedResponseResource>(url, {
			...body,
		});
	}

	public async deleteFileById(subscriptionId: string, fileId: string) {
		const url = `${this.baseurl}/${subscriptionId}/delete-file/${fileId}`;
		return this.deleteRequest<void>(url);
	}

	public async deleteDraft(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}`;
		return this.deleteRequest<void>(url);
	}

	public async submit(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}/submit`;
		return this.putRequest<SubscriptionResponseResource>(url);
	}

	public async submitCorporation(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}/submit/corporation`;
		return this.putRequest<SubscriptionResponseResource>(url);
	}

	public async getClientKnowledge(clientId: string) {
		const url = `${this.baseurl}/client-knowledge/${clientId}`;
		return this.getRequest<SubscriptionResponseResource>(url);
	}

	public async getCorporationKnowledge(corporationId: string) {
		const url = `${this.baseurl}/corporation-knowledge/${corporationId}`;
		return this.getRequest<SubscriptionResponseResource>(url);
	}

	public async getFiles(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}/files`;
		return this.getRequest<SubscriptionFileResponseResource[]>(url);
	}

	public uploadFilev2(subscriptionId: string, file: File) {
		const url = `${this.baseurl}/${subscriptionId}/upload-file`;
		const formadata = new FormData();
		formadata.append("file", file);
		return this.postRequestFormdata<FileResponseResource>(url, formadata);
	}

	public attachFile(subscriptionId: string, body: ProductSubscribedToFileUserRequestResource): Promise<void> {
		const url = `${this.baseurl}/${subscriptionId}/attach-file`;
		return this.postRequest(url, { ...body });
	}

	async removeFileByUid(subscriptionId: string, fileId: string) {
		const url = `${this.baseurl}/${subscriptionId}/remove-file/${fileId}`;
		return this.deleteRequest<void>(url);
	}

	public async syncDraftWithLastProductSheet(subscriptionId: string){
		const url = `${this.baseurl}/draft-subscription/${subscriptionId}/sync-with-last-product-sheet`;
		return this.putRequest<void>(url);
	}
}
