import { useContext } from "react";
import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";
import classNames from "classnames";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import Info from "components/elements/Icons/Info";
import InputElement, { EInputType } from "../InputElement";

export type NumberInput = {
	name: string;
	label: string;
	defaultValue?: string;
};

export type IProps = {
	name: string;
	inputs: NumberInput[];
	label?: string;
	className?: string;
	hidden?: boolean;
	readonly?: boolean;
};

export default function MultipleNumbersInput(props: IProps) {
	const { name, inputs, className, hidden = false, label, readonly = false } = props;
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(name);
	const hasErrors = errors?.length > 0;

	return (
		<div className={classNames(className, classes["root"], hidden && classes["hidden"])} id={name}>
			{label && !hidden && (
				<Typography typo={ITypo.P_LARGE_BOLD} color={ITypoColor.WILD_SAND_950} className={classes["label"]}>
					{label}
				</Typography>
			)}
			<div className={classes["inputs-container"]}>
				{hasErrors && !hidden && (
					<div className={classes["errors-container"]}>
						<div className={classes["error-icon"]}>
							<Info />
						</div>
						<div className={classes["errors"]}>
							{errors.map((message, i) => (
								<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
									{message}
								</Typography>
							))}
						</div>
					</div>
				)}
				{inputs.map((input) => {
					return (
						<div className={classes["input"]} key={input.name}>
							{input.label && <Typography typo={ITypo.P_MEDIUM}>{input.label}</Typography>}
							<div className={classes["percentage-container"]}>
								<InputElement name={input.name} defaultValue={input.defaultValue} type={EInputType.NUMBER} readonly={readonly} min={0} />
								<Typography typo={ITypo.P_LARGE} color={ITypoColor.WILD_SAND_500} className={classes["percentage"]}>
									€
								</Typography>
							</div>
						</div>
					);
				})}
			</div>
			<div className={classes["separator"]} />
		</div>
	);
}
