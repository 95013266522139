import { memo } from "react";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import LoginImage from "assets/images/login.jpg";
import Logo from "assets/images/logo.png";

type IProps = {
	children?: React.ReactNode;
};

function PageTemplateLogin(props: IProps) {
	return (
		<I18n>
			<div className={classes["root"]}>
				<div className={classes["content"]}>
					<div className={classes["body"]}>
						<img src={Logo} alt="Logo" className={classes["logo"]} />
						{props.children}
					</div>
					<div className={classes["image-container"]}>
						<img src={LoginImage} alt="Login" className={classes["image"]} />
					</div>
				</div>
				<div className={classes["contact"]}>
					{/*<I18n text={I18n.trslt(I18n.asset.pages.login.contact_wenimmo)} />*/}
				</div>
			</div>
		</I18n>
	);
}

export default memo(PageTemplateLogin);
