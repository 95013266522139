import { EAssociationProfesionnelle } from "common/enums/Office/EAssociationProfesionnelle";
import { EFormeJuridique } from "common/enums/Office/EFormeJuridique";
import { EHabilitation } from "common/enums/Office/EHabilitation";
import { ELigneMetier } from "common/enums/Office/ELigneMetier";
import { ETypeConseiller } from "common/enums/Office/ETypeConseiller";
import OfficeCreateRequestResource from "common/resources/Office/OfficeCreateRequestResource";
import OfficeResponseResource from "common/resources/Office/OfficeResponseResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement, { CheckBoxesOption } from "components/materials/Form/CheckboxesInputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import GooglePlacesAutocomplete from "components/materials/GooglePlacesAutocomplete";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import OfficeService from "services/OfficeService";
import ProductService from "services/ProductService";
import { container } from "tsyringe";
import FormUtils from "utils/FormUtils";

import { ICreatePartnerOutletContext } from "..";
import classes from "./classes.module.scss";

const productService = container.resolve(ProductService);
const officeService = container.resolve(OfficeService);

export default function CreateOffice() {
	const [productSheets, setProductSheets] = useState<ProductSheetLightResponseResource[]>([]);
	const [offices, setOffices] = useState<OfficeResponseResource[]>([]);
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const { goToNextStep, officeId, setOfficeId } = useOutletContext<ICreatePartnerOutletContext>();
	const [existingOffice, setExistingOffice] = useState(true);

	const [allChecked, setAllChecked] = useState(false);
	const [checkboxes, setCheckboxes] = useState<CheckBoxesOption[]>([]);

	useEffect(() => {
		productService.getAdminLastProductSheets().then(setProductSheets);
		officeService.get().then(setOffices);
	}, []);

	useEffect(() => {
		setCheckboxes(
			productSheets
				.map((productSheet) => ({
					value: productSheet.productId,
					label: productSheet.name,
					checked: false,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		);
	}, [productSheets]);

	const onProductChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value, checked } = event.target;
			if (value === "all") {
				setAllChecked(checked);
				setCheckboxes(checkboxes.map((option) => ({ ...option, checked })));
			} else {
				setCheckboxes(checkboxes.map((option) => (option.value === value ? { ...option, checked } : option)));
				const newCheckboxesState = checkboxes.map((option) => (option.value === value ? { ...option, checked } : option));
				const allOtherChecked = newCheckboxesState.every((option) => option.checked);
				setAllChecked(allOtherChecked);
			}
		},
		[checkboxes],
	);

	const onRadioChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setExistingOffice(event.target.value === "existing");
			setOfficeId(null);
		},
		[setOfficeId],
	);

	const onOfficeChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			if (!existingOffice) return;
			if (!value) return;
			setOfficeId(value.id.toString());
		},
		[existingOffice, setOfficeId],
	);

	const officesOptions = useMemo(
		() =>
			offices
				.map((office) => ({
					id: office.id,
					label: office.raisonSociale,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),

		[offices],
	);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			if (officeId && existingOffice) return goToNextStep(officeId);
			e.preventDefault();
			setErrors([]);
			OfficeCreateRequestResource.hydrate<OfficeCreateRequestResource>({
				raisonSociale: formData["raisonSociale"] as string,
				siret: formData["siret"] as string,
				rcs: formData["rcs"] as string,
				formeJuridique: formData["formeJuridique"] as EFormeJuridique,
				capitalSocial: parseFloat(formData["capitalSocial"] as string),
				accountOwnerName: formData["accountOwnerName"] as string,
				iban: (formData["iban"] as string).replace(" ", ""),
				bic: (formData["bic"] as string).replace(" ", ""),
				emailAdminitratif: (formData["emailAdminitratif"] as string) || undefined,
				phoneNumber: (formData["phoneNumber"] as string) || undefined,
				address: formData["address"] as string,
				lastNameManager: formData["lastNameManager"] as string,
				firstNameManager: formData["firstNameManager"] as string,
				birthDateManager: new Date(formData["birthDateManager"] as string),
				emailManager: formData["emailManager"] as string,
				typeConseiller: formData["typeConseiller"] as ETypeConseiller,
				habilitations: FormUtils.getEnumValues<EHabilitation>(formData, "habilitations", EHabilitation),
				associationProfessionnelle: formData["associationProfessionnelle"] as EAssociationProfesionnelle,
				numeroOrias: formData["numeroOrias"] as string,
				ligneMetiers: FormUtils.getEnumValues<ELigneMetier>(formData, "ligneMetiers", ELigneMetier),
				products: FormUtils.getOfficeProductRequestResource(
					formData,
					productSheets.map((productSheet) => productSheet.productId),
				),
			})
				.validateOrReject()
				.then((resource) => officeService.post(resource))
				.then((resource) => {
					setOfficeId(resource.id);
					goToNextStep(resource.id);
				})
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[existingOffice, goToNextStep, officeId, productSheets, setOfficeId],
	);

	return (
		<div className={classes["root"]}>
			<Form className={classes["form-container"]} onSubmit={onSubmit} errors={errors}>
				<RadioInputElement
					name="radio"
					options={[
						{
							label: I18n.asset.pages.create_partner.sub_pages.create_office.existing_office,
							value: "existing",
						},
						{
							label: I18n.asset.pages.create_partner.sub_pages.create_office.new_office,
							value: "new",
						},
					]}
					defaultValue="existing"
					onChange={onRadioChange}
				/>
				{existingOffice && (
					<MuiSelectInputElement
						name="office"
						placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.find_office}
						options={officesOptions}
						onChange={onOfficeChange}
						defaultValue={officesOptions.find((option) => option.id === officeId)}
					/>
				)}
				{!existingOffice && (
					<div className={classes["form"]}>
						<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_office.legal_identity}</Typography>
						<div className={classes["fields"]}>
							<InputElement
								name="raisonSociale"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.company_name.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.company_name.placeholder}
							/>
							<InputElement
								name="siret"
								isNumericString={{ allowSymbols: false }}
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.siret.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.siret.placeholder}
							/>
							<MuiSelectInputElement
								name="formeJuridique"
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.legal_form.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.legal_form.placeholder}
								options={Object.values(EFormeJuridique).map((formeJuridique) => ({
									id: formeJuridique,
									label: I18n.asset.enums.EFormeJuridique[formeJuridique],
								}))}
							/>
							<InputElement
								name="rcs"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.rcs.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.rcs.placeholder}
							/>
							<InputElement
								name="capitalSocial"
								type={EInputType.NUMBER}
								min={0}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.share_capital.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.share_capital.placeholder}
							/>
						</div>
						<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_office.authorisation}</Typography>
						<div className={classes["fields"]}>
							<MuiSelectInputElement
								name="typeConseiller"
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.type_advisor.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.type_advisor.placeholder}
								options={Object.values(ETypeConseiller).map((type) => ({
									id: type,
									label: I18n.asset.enums.ETypeConseiller[type],
								}))}
							/>
							<CheckboxesInputElement
								name="habilitations"
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.habilitations.label}
								options={Object.values(EHabilitation).map((habilitation) => ({
									value: habilitation,
									label: I18n.asset.enums.EHabilitation[habilitation],
								}))}
							/>
							<MuiSelectInputElement
								name="associationProfessionnelle"
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.professional_association.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.professional_association.placeholder}
								options={Object.values(EAssociationProfesionnelle).map((association) => ({
									id: association,
									label: I18n.asset.enums.EAssociationProfesionnelle[association],
								}))}
							/>
							<InputElement
								name="numeroOrias"
								type={EInputType.TEXT}
								isNumericString={{ allowSymbols: false }}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.numero_orias.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.numero_orias.placeholder}
							/>
							<CheckboxesInputElement
								name="ligneMetiers"
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.business_lines.label}
								options={Object.values(ELigneMetier).map((ligneMetier) => ({
									value: ligneMetier,
									label: I18n.asset.enums.ELigneMetier[ligneMetier],
								}))}
							/>
						</div>
						<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_office.manager_data}</Typography>
						<div className={classes["fields"]}>
							<InputElement
								name="lastNameManager"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.name.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.name.placeholder}
							/>
							<InputElement
								name="firstNameManager"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.first_name.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.first_name.placeholder}
							/>
							<DatePickerInputElement
								name="birthDateManager"
								maxDate={new Date()}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.birth_date.label}
								isAbsoluteDate
							/>
							<InputElement
								name="emailManager"
								type={EInputType.EMAIL}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.email_manager.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.email_manager.placeholder}
							/>
						</div>
						<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_office.office_coordinates}</Typography>
						<div className={classes["fields"]}>
							<InputElement
								name="emailAdminitratif"
								type={EInputType.EMAIL}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.email_administratif.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.email_administratif.placeholder}
							/>
							<InputElement
								name="phoneNumber"
								isNumericString={{ allowSymbols: true }}
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.phone_number.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.phone_number.placeholder}
							/>
							<GooglePlacesAutocomplete
								name="address"
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.address.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.address.placeholder}
							/>
						</div>

						<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_office.bank_data}</Typography>
						<div className={classes["fields"]}>
							<InputElement
								name="accountOwnerName"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.account_owner.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.account_owner.placeholder}
							/>
							<InputElement
								name="iban"
								format="iban"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.iban.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.iban.placeholder}
							/>
							<InputElement
								name="bic"
								type={EInputType.TEXT}
								label={I18n.asset.pages.create_partner.sub_pages.create_office.form.bic.label}
								placeholder={I18n.asset.pages.create_partner.sub_pages.create_office.form.bic.placeholder}
							/>
						</div>
						<div className={classes["fields"]}>
							<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_office.office_products}</Typography>
							<CheckboxesInputElement
								name="products"
								options={[
									{
										label: I18n.asset.pages.create_partner.sub_pages.create_office.check_all_spci,
										value: "all",
										checked: allChecked,
									},
									...checkboxes,
								]}
								onChange={onProductChange}
							/>
						</div>
					</div>
				)}
				<Button type="submit" disabled={existingOffice && !officeId}>
					{I18n.asset.common.next}
				</Button>
			</Form>
		</div>
	);
}
