import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	type: EProductDocumentType;
	productSheets: ProductSheetResponseResource[];
};

const productService = container.resolve(ProductService);

export default function HistoryTemplateFileModal(props: IProps) {
	const { isOpen, onClose, type, productSheets } = props;
	return (
		<Modal className={classes["root"]} isOpen={isOpen} onClose={onClose} fullwidth>
			<Typography typo={ITypo.H1}>{I18n.asset.pages.product.data_history}</Typography>
			<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.enums.EProductDocumentType[type]}</Typography>
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
				<Table columnsHead={getColumnsHead(false)} onNext={null} rows={buildRows(productSheets, type)} searchbarDisabled></Table>
			</HasRules>
			<HasRules requiredRules={{ AND: { access_platform__cgp: true, access_platform__middle_office: false } }}>
				<Table columnsHead={getColumnsHead(true)} onNext={null} rows={buildRows(productSheets, type)} searchbarDisabled></Table>
			</HasRules>
			<div>
				<Button onClick={onClose} variant={EButtonVariant.CONTAINED}>
					{I18n.asset.common.close}
				</Button>
			</div>
		</Modal>
	);
}

function buildRows(productSheets: ProductSheetResponseResource[], type: EProductDocumentType): IRowProps[] {
	const rows = productSheets.map((productSheet) => {
		const lastTemplateFile = productSheet.ProductDocumentsTypeToFiles?.find((file) => file.type === type);

		return {
			rowKey: productSheet.id,
			type: I18n.asset.enums.EProductDocumentType[type],
			fileId: lastTemplateFile?.fileId ?? "-",
			modificationDate: productSheet.createdAt,
			creator: productSheet.creator ? `${productSheet.creator.firstName} ${productSheet.creator.lastName}` : "-",
			download: lastTemplateFile && (
				<a
					href={productService.buildTemplateFileUrlByFileId(lastTemplateFile.fileId)}
					download={I18n.asset.enums.EProductDocumentType[lastTemplateFile.type]}
					target="_blank"
					rel="noopener noreferrer">
					<ArrowDownTrayIcon width={20} height={20} />
				</a>
			),
		};
	});

	// For each row, compare it to the next one, if the next one is different, keep the actual row
	const rowsFiltered: typeof rows = [];
	for (let i = 0; i < rows.length; i++) {
		if ((rows[i + 1] && rows[i]!.fileId !== rows[i + 1]!.fileId) || !rows[i + 1]) {
			rowsFiltered.push(rows[i]!);
		}
	}
	return rowsFiltered;
}

function getColumnsHead(isMo: boolean): TableColumn[] {
	const head = [
		{
			headContent: I18n.asset.pages.products.history_table.columns.data,
			key: "type",
		},
		{
			headContent: I18n.asset.pages.products.history_table.columns.modificationDate,
			formatType: ECellType.DATE,
			key: "modificationDate",
		},
		{
			headContent: I18n.asset.pages.products.history_table.columns.creator,
			key: "creator",
		},
		{
			headContent: "",
			key: "download",
		},
	];

	// if isMo true, remove the column creator
	if (isMo) head.pop();
	return head;
}
