import SubscriptionApi from "api/wenImmo/SubscriptionApi";
import { GenericProduct } from "common/resources/Scpi/PhysicalPerson/GenericProduct";
import SubscriptionCreateRequestResource from "common/resources/Subscription/SubscriptionCreateRequestResource";
import SubscriptionCorporationCreateRequestResource from "common/resources/Subscription/SubscriptionCorporationCreateRequestResource";
import SubscriptionUpdateRequestResource from "common/resources/Subscription/SubscriptionUpdateRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import SubscriptionServiceClassToken from "common/resources/injectables/SubscriptionServiceClassToken";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";
import UpdatePaymentMethodRequestResource from "common/resources/ProductSubscribed/UpdatePaymentMethodRequestResource";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import SubscriptionFileResponseResource from "common/resources/Subscription/Files/SubscriptionFileResponseResource";

import GenericAutreCgpRequestResource from "common/resources/Scpi/PhysicalPerson/GenericAutreCgpRequestResource";
import GenericConnaissanceExperienceRequestResource from "common/resources/Scpi/PhysicalPerson/GenericConnaissanceExperienceRequestResource";
import GenericFinanceDurableRequestResource from "common/resources/Scpi/PhysicalPerson/GenericFinanceDurableRequestResource";
import GenericPatrimoineRequestResource from "common/resources/Scpi/PhysicalPerson/GenericPatrimoineRequestResource";
import GenericTestAdequationRequestResource from "common/resources/Scpi/PhysicalPerson/GenericTestAdequationRequestResource";

import GenericAutreCgpRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericAutreCgpRequestResource";
import GenericConnaissanceExperienceRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericConnaissanceExperienceRequestResource";
import GenericFinanceDurableRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericFinanceDurableRequestResource";
import GenericPatrimoineRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericPatrimoineRequestResource";
import GenericTestAdequationRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericTestAdequationRequestResource";

import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSubscribedToFileUserRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileUserRequestResource";
import { IPagination } from "components/elements/InfiniteScroll";

@singleton()
export default class SubscriptionService implements SubscriptionServiceClassToken {
	private api: SubscriptionApi = container.resolve(SubscriptionApi);

	public async exists(_id: string) {
		console.warn("ProductService.exists() is not implemented");
		return true;
	}

	public async countDrafts(): Promise<{ count: number }> {
		return this.api.countDrafts().catch(toValidationError);
	}

	public async get(): Promise<SubscriptionResponseResource[]> {
		return this.api.get().catch(toValidationError);
	}

	public async getByUid(uid: string): Promise<SubscriptionResponseResource> {
		return this.api.getByUid(uid).catch(toValidationError);
	}

	public async getAllDrafts(pagination: IPagination, search?: string | null): Promise<SubscriptionResponseResource[]> {
		return this.api.getAllDrafts(pagination, search).catch(toValidationError);
	}

	public async post(body: SubscriptionCreateRequestResource): Promise<SubscriptionResponseResource> {
		return this.api.post(body).catch(toValidationError);
	}

	public async postCorporation(body: SubscriptionCorporationCreateRequestResource): Promise<SubscriptionResponseResource> {
		return this.api.postCorporation(body).catch(toValidationError);
	}

	public async uploadFile(subscriptionId: string, type: ESubFilesTypes, file: File): Promise<SubscriptionFileResponseResource> {
		return this.api.uploadFile(subscriptionId, type, file).catch(toValidationError);
	}

	public async updateCategory(
		body:
			| GenericPatrimoineRequestResource
			| GenericConnaissanceExperienceRequestResource
			| GenericTestAdequationRequestResource
			| GenericFinanceDurableRequestResource
			| GenericAutreCgpRequestResource,
		id: string,
		category: keyof GenericProduct,
	): Promise<SubscriptionResponseResource> {
		return this.api.updateCategory(body, id, category).catch(toValidationError);
	}

	public async updateCategoryCorporation(
		body:
			| GenericPatrimoineRequestResourceCorporation
			| GenericConnaissanceExperienceRequestResourceCorporation
			| GenericTestAdequationRequestResourceCorporation
			| GenericFinanceDurableRequestResourceCorporation
			| GenericAutreCgpRequestResourceCorporation,
		id: string,
		category: keyof GenericProduct,
	): Promise<SubscriptionResponseResource> {
		return this.api.updateCategoryCorporation(body, id, category).catch(toValidationError);
	}

	public async put(body: SubscriptionUpdateRequestResource): Promise<SubscriptionResponseResource> {
		return this.api.put(body).catch(toValidationError);
	}

	public async updatePaymentInformations(body: UpdatePaymentMethodRequestResource): Promise<ProductSubscribedResponseResource> {
		return this.api.updatePaymentInformations(body).catch(toValidationError);
	}

	public async deleteFileById(subscriptionId: string, fileId: string): Promise<void> {
		return this.api.deleteFileById(subscriptionId, fileId).catch(toValidationError);
	}

	public async deleteDraft(subscriptionId: string): Promise<void> {
		return this.api.deleteDraft(subscriptionId).catch(toValidationError);
	}

	public async submit(subscriptionId: string): Promise<SubscriptionResponseResource> {
		return this.api.submit(subscriptionId).catch(toValidationError);
	}

	public async submitCorporation(subscriptionId: string): Promise<SubscriptionResponseResource> {
		return this.api.submitCorporation(subscriptionId).catch(toValidationError);
	}

	public async getClientKnowledge(clientId: string): Promise<SubscriptionResponseResource> {
		return this.api.getClientKnowledge(clientId).catch(toValidationError);
	}

	public async getCorporationKnowledge(corporationId: string): Promise<SubscriptionResponseResource> {
		return this.api.getCorporationKnowledge(corporationId).catch(toValidationError);
	}

	public async getFiles(subscriptionId: string): Promise<SubscriptionFileResponseResource[]> {
		return this.api.getFiles(subscriptionId).catch(toValidationError);
	}

	public async uploadFilev2(subscriptionId: string, file: File): Promise<FileResponseResource> {
		return this.api.uploadFilev2(subscriptionId, file).catch(toValidationError);
	}

	public async attachFile(subscriptionId: string, body: ProductSubscribedToFileUserRequestResource) {
		return this.api.attachFile(subscriptionId, body).catch(toValidationError);
	}

	public async removeFileByUid(subscriptionId: string, fileId: string) {
		return this.api.removeFileByUid(subscriptionId, fileId).catch(toValidationError);
	}

	public async syncDraftWithLastProductSheet(subscriptionId: string): Promise<void> {
		return this.api.syncDraftWithLastProductSheet(subscriptionId).catch(toValidationError);
	}
}
