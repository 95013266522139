import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import Button from "components/elements/Button";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import * as P from "ts-pattern";
import { container } from "tsyringe";

const productSubscribedService = container.resolve(ProductSubscribedService);
const configService = container.resolve(ApplicationConfig);
const subscriptionInformationModule = container.resolve(ModuleConfig).get().modules.pages.SubscriptionInformation;

type IProps = {
	subSteps: ESubStep[];
	dateLabel: string;
};

export default function SubscriptionsTable(props: IProps) {
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const fetchData = useCallback(
		async () =>
			productSubscribedService.getAllByStep(props.subSteps, pagination.current, search.current).then((data) => {
				if (data.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(data)]);
				pagination.current.skip += pagination.current.take;
				return data;
			}),
		[props.subSteps],
	);

	const onNext = useCallback(
		(release: () => void) => {
			fetchData().then((data) => {
				if (!data.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchData],
	);

	useEffect(() => {
		productSubscribedService.countBySteps(props.subSteps).then(setCount);
	}, [props.subSteps]);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	const columsHead: TableColumn[] = useMemo(
		() => [
			{
				headContent: I18n.asset.pages.subscriptions.sub_pages.in_progress.subscriber,
				key: "subscriber",
			},
			{
				headContent: I18n.asset.pages.subscriptions.sub_pages.in_progress.type,
				key: "type",
			},
			{
				headContent: I18n.asset.pages.subscriptions.table.product_name,
				key: "product_name",
			},
			{
				headContent: I18n.asset.pages.subscriptions.table.amount_subscribed,
				key: "amount_subscribed",
				formatType: ECellType.EURO,
			},
			{
				headContent: I18n.asset.pages.subscriptions.table.payment_method,
				key: "payment_method",
			},
			{
				headContent: props.dateLabel,
				key: "date",
			},
			{
				headContent: "",
				key: "consult",
				widthPercentage: 5,
			},
		],
		[props.dateLabel],
	);

	return (
		<Table
			columnsHead={columsHead}
			count={count.count}
			rows={rows}
			placeholderSearchbar={I18n.asset.pages.subscriptions.table.find_subscrition}
			onNext={onNext}
			onSearch={onSearch}
		/>
	);
}

function buildRows(products: ProductSubscribedResponseResource[]): IRowProps[] {
	return products.map((product) => {
		return {
			rowKey: product.id,
			subscriber: getSubscriberIdentity(product),
			type: product.subscription && I18n.asset.enums.ESubscriptionType[product.subscription?.subscriptionType],
			product_name: product.productSheet!.name,
			amount_subscribed: product.quantity * product.productSheet!.price,
			payment_method: I18n.asset.enums.EPaymentMethod[product.paymentMethod],
			date: getDateValue(product),
			consult: (
				<Link to={subscriptionInformationModule.props.path.replace(":productSubscribedId", product.id)}>
					<Button>
						{I18n.asset.common.consults}
						<ArrowLongRightIcon />
					</Button>
				</Link>
			),
		};
	});
}

function getDateValue(productSubscribed: ProductSubscribedResponseResource) {
	return P.match(productSubscribed.step)
		.with(ESubStep.validated, () => new Date(productSubscribed.validationDate ?? "").toLocaleDateString())
		.with(ESubStep.processing, () => I18n.asset.pages.subscriptions.analyse_processing)
		.otherwise(() => new Date(productSubscribed.updatedAt ?? "").toLocaleDateString());
}

export function getSubscriberIdentity(productSubscribed: ProductSubscribedResponseResource | null) {
	const subscriber = productSubscribed?.subscriber;
	const corporationSubscriber = productSubscribed?.corporationSubscriber;
	if (subscriber) return `${subscriber.firstName} ${subscriber.lastName}`;
	if (corporationSubscriber) return corporationSubscriber.raisonSociale;
	return "-";
}
